import { useEffect } from 'react';
import { useAppDispatch } from '../../../redux/app/hooks';
import { DateTypes } from '../@types';
import { selectTransactions } from '../redux/transactions/transactionSlice';

export const useFilterTransactions = (date: DateTypes | null) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (date) {
      dispatch(selectTransactions(date));
    }
    // eslint-disable-next-line
  }, [date]);
};
