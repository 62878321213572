import { CustomerTypes } from '../@types';

export const CUSTOMERS: CustomerTypes[] = [
  { id: '1', name: 'max', officerId: '4', totalProfitability: 43253 },
  { id: '2', name: 'josephine', officerId: '4', totalProfitability: 78457 },
  { id: '3', name: 'dereck', officerId: '4', totalProfitability: 35034 },
  { id: '4', name: 'devon', officerId: '5', totalProfitability: 5634 },
  { id: '5', name: 'simon', officerId: '5', totalProfitability: 89654 },
  { id: '6', name: 'dolly', officerId: '5', totalProfitability: 1236 },
  { id: '7', name: 'brad', officerId: '6', totalProfitability: 46908 },
  { id: '8', name: 'keith', officerId: '6', totalProfitability: 76890 },
  { id: '9', name: 'helen', officerId: '6', totalProfitability: 26483 },
  { id: '10', name: 'anna', officerId: '7', totalProfitability: 47901 },
  { id: '11', name: 'logan', officerId: '7', totalProfitability: 36789 },
  { id: '12', name: 'anthony', officerId: '7', totalProfitability: 87659 },
];
