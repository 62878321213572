import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { resetAllAction } from '../actions';
import { CurrencyChangeInitialStateTypes } from '../../@types';
import changeCurrencyService from './currencyChangeService';

const initialState: CurrencyChangeInitialStateTypes = {
  exchange: {
    currency: 'EURO',
    moneySign: '€',
    exchangeRate: 1,
  },
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
};

export const changeCurrency = createAsyncThunk(
  'currency/changeCurrency',
  (currency: string) => changeCurrencyService.changeCurrency(currency)
);

export const currencyChangeSlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changeCurrency.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changeCurrency.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.exchange = action.payload!;
      })
      .addCase(resetAllAction, () => initialState);
  },
});

export const { reset } = currencyChangeSlice.actions;
export default currencyChangeSlice.reducer;
