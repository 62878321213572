import { useEffect } from 'react';
import { useAppDispatch } from '../../../redux/app/hooks';
import { selectApprovals } from '../redux/approvals/approvalSlice';
import { UserTypes, CustomerTypes } from '../../../_mock/@types';

export const useSelectApprovals = (
  selectedUser: UserTypes | CustomerTypes | null
) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedUser) {
      dispatch(selectApprovals(selectedUser.id));
    }
    // eslint-disable-next-line
  }, [selectedUser]);
};
