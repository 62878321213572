import {
  CUSTOMERS,
  APPROVALS,
  TRANSACTIONS,
  SCENARIOS,
} from '../../../_mock/data';
import { mergeById } from '../utils';
import { FinalDataTypes } from '../@types';

const transformedData = mergeById(
  CUSTOMERS,
  APPROVALS,
  TRANSACTIONS,
  SCENARIOS
);

export const transformedData2 = transformedData.map((element: any) => ({
  customerName: element.name,
  totalProfitability: element.totalProfitability,
  products: element.scenarios.flatMap((scenario: any) => scenario.products),
  transactions: element.transactions,
  amountDue: null,
  officerName: element.scenarios[0].createdBy.name,
  key: element.key,
}));

export const transformFinalData = (moneySign: string, exchangeRate: number) => {
  const finalData: FinalDataTypes[] = transformedData2.map((element: any) => ({
    ...element,
    totalProfitability: `${moneySign} ${Math.round(
      element.totalProfitability * exchangeRate
    )}`,
    productsOwned: element.products.filter(
      (product: any) => product.isActual === true
    ).length,
    loans: element.products.filter((product: any) => product.type === 'loan')
      .length,
    services: element.products.filter(
      (product: any) => product.type === 'service'
    ).length,
    completedTransactions: element.transactions.filter(
      (transaction: any) => transaction.paid === true
    ).length,
    pendingTransactions: element.transactions.filter(
      (transaction: any) => transaction.paid === false
    ).length,
    amountDue: `${moneySign} ${Math.round(element.totalProfitability * 0.3)}`,
  }));
  return finalData;
};
