import { ReactNode } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import DashboardPage from '../modules/dashboard/pages/dashboard/DashboardPage';
import LoginPage from '../modules/auth/pages/login/LoginPage';
import ApprovalsPage from '../modules/approvals/pages/approvals/ApprovalsPage';
import EventViewerPage from '../modules/events/pages/event-viewer/EventViewerPage';
import CustomerManagementPage from '../modules/customer-management/pages/customer-management';
import ScenariosListPage from '../modules/scenarios/pages/scenarios-list';
import ProductPage from '../modules/scenarios/pages/products';
import AuthGuard from '../modules/auth/guards/AuthGuard';
import ApprovalsGuard from '../modules/approvals/guards/ApprovalsGuard';
import MainLayout from '../components/main-layout';

type WrapperPropsTypes = {
  children: ReactNode;
};

const Wrapper: React.FC<WrapperPropsTypes> = ({ children }) => {
  return (
    <AuthGuard>
      <MainLayout>{children}</MainLayout>
    </AuthGuard>
  );
};

const Router: React.FC = () => {
  return useRoutes([
    {
      path: 'auth',
      children: [{ path: 'login', element: <LoginPage /> }],
    },

    {
      path: '/',
      element: <Navigate to='/dashboard' replace />,
    },
    {
      path: '/dashboard',
      element: (
        <Wrapper>
          <DashboardPage />
        </Wrapper>
      ),
    },
    {
      path: 'customer-management',
      element: (
        <Wrapper>
          <CustomerManagementPage />
        </Wrapper>
      ),
    },
    {
      path: 'scenarios',
      element: (
        <Wrapper>
          <ScenariosListPage />
        </Wrapper>
      ),
    },
    {
      path: 'scenarios/scenario-:scenarioId',
      element: (
        <Wrapper>
          <ProductPage />
        </Wrapper>
      ),
    },
    {
      path: 'approvals',
      element: (
        <Wrapper>
          <ApprovalsGuard>
            <ApprovalsPage />
          </ApprovalsGuard>
        </Wrapper>
      ),
    },
    {
      path: 'events',
      element: (
        <Wrapper>
          <EventViewerPage />
        </Wrapper>
      ),
    },
  ]);
};

export default Router;
