import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../redux/app/hooks';
import { useFilterTransactions } from '../../hooks';
import { calculateTotalAmount, changeCurrencyFormat } from '../../utils';
import { LineChart, Line, Tooltip } from 'recharts';
import { Card, Typography, Space } from 'antd';
import { TRANSACTIONS } from '../../../../_mock/data';
import { TransactionTypes } from '../../../../_mock/@types';
import { bankRevenueCardStyles, sharedStyles } from './styles';

const { Title } = Typography;

const fulfiledTransactions = TRANSACTIONS.filter(
  (transaction: TransactionTypes) => transaction.paid === true
);

const totalBankRevenueSum = calculateTotalAmount(fulfiledTransactions);

const BankRevenueCard: React.FC = () => {
  // eslint-disable-next-line
  const [transactions, setTransactions] =
    useState<TransactionTypes[]>(fulfiledTransactions);
  const [selectedTransactions, setSelectedTransactions] = useState<
    TransactionTypes[] | null
  >(null);
  const { date } = useAppSelector((state) => state.dates);
  const { recentTransactions } = useAppSelector((state) => state.transactions);
  const { selectedUser } = useAppSelector((state) => state.hierarchyTree);
  const {
    exchange: { exchangeRate, moneySign },
  } = useAppSelector((state) => state.currency);
  const [totalBankRevenue, setTotalBankRevenue] = useState<string | null>(
    changeCurrencyFormat(exchangeRate, moneySign, totalBankRevenueSum)
  );

  useFilterTransactions(date);

  useEffect(() => {
    if (!date) {
      setSelectedTransactions(null);
    }
  }, [date]);

  useEffect(() => {
    if (exchangeRate || moneySign) {
      const totalBankRevenueAmount = changeCurrencyFormat(
        exchangeRate,
        moneySign,
        totalBankRevenueSum
      );
      setTotalBankRevenue(totalBankRevenueAmount);
    }
  }, [exchangeRate, moneySign]);

  useEffect(() => {
    if (recentTransactions && !selectedUser) {
      const transactions = recentTransactions.map(
        (transaction: TransactionTypes) => ({
          ...transaction,
          amount: Math.round(transaction.amount * exchangeRate),
        })
      );

      const recentTransactionsAmount = calculateTotalAmount(transactions);
      const recentTotalBankRevenue = changeCurrencyFormat(
        exchangeRate,
        moneySign,
        recentTransactionsAmount
      );

      setTotalBankRevenue(recentTotalBankRevenue);
      setSelectedTransactions(transactions);
    }
  }, [recentTransactions, exchangeRate, moneySign, selectedUser]);

  useEffect(() => {
    //@ts-ignore
    if (recentTransactions && selectedUser?.officerId) {
      const transactions = recentTransactions
        .filter(
          (transaction: TransactionTypes) =>
            transaction.customerId === selectedUser.id
        )
        .map((transaction: TransactionTypes) => ({
          ...transaction,
          amount: Math.round(transaction.amount * exchangeRate),
        }));

      const recentTransactionsAmount = calculateTotalAmount(transactions);
      const recentTotalBankRevenue = changeCurrencyFormat(
        exchangeRate,
        moneySign,
        recentTransactionsAmount
      );

      setTotalBankRevenue(recentTotalBankRevenue);
      setSelectedTransactions(transactions);
    }
  }, [recentTransactions, exchangeRate, moneySign, selectedUser]);

  return (
    <Card
      hoverable
      //@ts-ignore
      style={bankRevenueCardStyles.cardStyles}
      cover={
        <>
          <Space direction='vertical' style={sharedStyles.spaceStyles}>
            <Title style={sharedStyles.titleStyles} level={4}>
              Total Bank Revenue
            </Title>
            <Title style={sharedStyles.titleStyles} level={4}>
              {totalBankRevenue}
            </Title>
          </Space>
          <LineChart
            width={240}
            height={300}
            data={selectedTransactions ? selectedTransactions : transactions}
            margin={sharedStyles.chartStyles}
          >
            <Tooltip />
            <Line
              dot={false}
              type='monotone'
              dataKey='amount'
              stroke='black'
              strokeWidth={2}
            />
          </LineChart>
        </>
      }
    />
  );
};

export default BankRevenueCard;
