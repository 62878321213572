import { UserTypes } from '../@types';

export const USERS: UserTypes[] = [
  { id: '1', category: 'unitManager', name: 'john', managerId: null },
  { id: '2', category: 'sectorManager', name: 'mairy', managerId: '1' },
  { id: '3', category: 'sectorManager', name: 'nicole', managerId: '1' },
  { id: '4', category: 'officer', name: 'george', managerId: '2' },
  { id: '5', category: 'officer', name: 'manuel', managerId: '2' },
  { id: '6', category: 'officer', name: 'paul', managerId: '3' },
  { id: '7', category: 'officer', name: 'peter', managerId: '3' },
];
