import { useState } from 'react';
import { Space, Steps, Button } from 'antd';
import CustomerInfoTable from './CustomerInfoTable';
import ProfitabilityChart from './ProfitabilityChart';
import ProductAnalysisTables from './ProductAnalysisTables';

const { Step } = Steps;

const ReviewScenarioModalContent = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);

  const steps = [
    {
      title: 'Customer Info',
      content: <CustomerInfoTable isPortal={false} />,
      description: 'This is a description',
      style: {
        marginBottom: '4rem',
      },
    },
    {
      title: 'Profitability',
      content: <ProfitabilityChart isPortal={false} />,
      style: {
        marginBottom: '4rem',
      },
      description: 'This is a description',
    },
    {
      title: 'Product Analysis',
      content: <ProductAnalysisTables isPortal={false} />,
      style: {
        marginBottom: '0px',
      },
      description: 'This is a description',
    },
  ];

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <>
      <Space
        align='center'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '60vh',
        }}
      >
        <Steps direction='vertical' type='default' current={currentStep}>
          {steps.map((item) => (
            <Step
              // style={item.style}
              description={item.description}
              key={item.title}
              title={item.title}
            />
          ))}
        </Steps>
        {steps[currentStep].content}
      </Space>
      <Space style={{ display: 'flex', justifyContent: 'end' }}>
        {currentStep > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={() => handlePrevStep()}>
            Previous
          </Button>
        )}
        {currentStep < steps.length - 1 && (
          <Button type='primary' onClick={() => handleNextStep()}>
            Next
          </Button>
        )}
        {currentStep === steps.length - 1 && (
          <Button
            type='primary'
            onClick={() => window.alert('Processing complete!')}
          >
            Submit
          </Button>
        )}
      </Space>
    </>
  );
};

export default ReviewScenarioModalContent;
