import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../../../redux/app/hooks';
import { PATH_AUTH } from '../../../routes/paths';
import { AuthGuardProps } from '../@types';

const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
  const { authUser } = useAppSelector((state) => state.auth);

  return authUser ? <>{children}</> : <Navigate to={PATH_AUTH.login} />;
};

export default AuthGuard;
