import { Modal } from 'antd';
import { ApprovalModalContent } from './ApprovalModalContent';

const ApprovalModal = () => {
  let modal = Modal;

  modal.info({
    title: 'Approval Info',
    width: '50%',
    content: ApprovalModalContent(),
    maskClosable: true,
    icon: null,
  });
};

export default ApprovalModal;
