export const bankRevenueCardStyles = {
  cardStyles: {
    width: '240px',
    height: '300px',
    border: '2px solid',
    borderRadius: '25px',
    objectFit: 'contain',
    backgroundColor: ' #ffcccc',
  },
};

export const totalRepaymentCardStyles = {
  cardStyles: {
    width: '240px',
    height: '300px',
    border: '2px solid',
    borderRadius: '25px',
    objectFit: 'contain',
    backgroundColor: '#CBC3E3',
  },
};

export const clientProfitabilityCardStyles = {
  cardStyles: {
    width: '240px',
    height: '300px',
    border: '2px solid',
    borderRadius: '25px',
    objectFit: 'contain',
    backgroundColor: '#00FFFF',
  },
};

export const sharedStyles = {
  spaceStyles: {
    marginTop: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  titleStyles: { marginBottom: '0px', marginTop: '0px' },
  chartStyles: { top: 10, right: 20, left: 20, bottom: 0 },
};
