import domtoimage from 'dom-to-image';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const handleExportToPdf = async (elementId: string) => {
  const image = await domtoimage.toJpeg(document.getElementById(elementId)!, {
    quality: 1,
  });

  const docDefinition = {
    content: [
      {
        image,
        width: 510,
      },
    ],
  };
  pdfMake.createPdf(docDefinition).open();
};

export const handleScenarioReportExportToPdf = async (
  elementIdArray: string[],
  ScenarioReportData: any
) => {
  const image1 = await domtoimage.toJpeg(
    document.getElementById(elementIdArray[0]) as Node,
    {
      quality: 1,
    }
  );
  const image2 = await domtoimage.toJpeg(
    document.getElementById(elementIdArray[1]) as Node,
    {
      quality: 1,
    }
  );
  const image3 = await domtoimage.toJpeg(
    document.getElementById(elementIdArray[2]) as Node,
    {
      quality: 1,
    }
  );

  const docDefinition = {
    footer: (currentPage: any, pageCount: any) =>
      currentPage.toString() + ' of ' + pageCount,
    content: [
      {
        style: {
          fillColor: '#00008B',
          color: 'white',
          bold: true,
          fontSize: 14,
        },
        table: {
          headerRows: 1,
          widths: ['*'],
          body: [
            [
              {
                text: [
                  `Scenario Report #${ScenarioReportData.scenarioId}`,
                  ' ',
                  ' ',
                  {
                    text: `  ${ScenarioReportData.approvalStatus}  `,
                    background: '#FFFFF0',
                    color: 'orange',
                    fontSize: 10,
                    bold: false,
                  },
                ],
              },
            ],
          ],
        },
        layout: 'noBorders',
      },
      {
        style: { fillColor: '#00008B', color: 'white' },
        table: {
          headerRows: 1,
          widths: [55, 50, 65, 110, 75, '*'],
          body: [
            [
              { text: 'Customer', style: 'tableHeader' },
              { text: 'Officer', style: 'tableHeader' },
              { text: 'Profitability', style: 'tableHeader' },
              { text: 'Profitability (1 year)', style: 'tableHeader' },
              { text: 'Amount Due', style: 'tableHeader' },
              { text: 'Amount Due (1 year)', style: 'tableHeader' },
            ],
            [
              ScenarioReportData.name,
              ScenarioReportData.officerName,
              ScenarioReportData.profitability,
              ScenarioReportData.futureProfitability,
              ScenarioReportData.amountDue,
              ScenarioReportData.futureAmountDue,
            ],
          ],
        },
        layout: 'noBorders',
      },

      '\n',
      {
        image: image1,
        width: 510,
        pageBreak: 'after',
      },
      {
        style: {
          fillColor: '#00008B',
          color: 'white',
          bold: true,
          fontSize: 14,
        },
        table: {
          headerRows: 1,
          widths: ['*'],
          body: [
            [
              {
                text: [
                  `Scenario Report #${ScenarioReportData.scenarioId}`,
                  ' ',
                  ' ',
                  {
                    text: `  ${ScenarioReportData.approvalStatus}  `,
                    background: '#FFFFF0',
                    color: 'orange',
                    fontSize: 10,
                    bold: false,
                  },
                ],
              },
            ],
          ],
        },
        layout: 'noBorders',
      },
      {
        style: { fillColor: '#00008B', color: 'white' },
        table: {
          headerRows: 1,
          widths: [55, 50, 65, 110, 75, '*'],
          body: [
            [
              { text: 'Customer', style: 'tableHeader' },
              { text: 'Officer', style: 'tableHeader' },
              { text: 'Profitability', style: 'tableHeader' },
              { text: 'Profitability (1 year)', style: 'tableHeader' },
              { text: 'Amount Due', style: 'tableHeader' },
              { text: 'Amount Due (1 year)', style: 'tableHeader' },
            ],
            [
              ScenarioReportData.name,
              ScenarioReportData.officerName,
              ScenarioReportData.profitability,
              ScenarioReportData.futureProfitability,
              ScenarioReportData.amountDue,
              ScenarioReportData.futureAmountDue,
            ],
          ],
        },
        layout: 'noBorders',
      },
      '\n',
      {
        image: image2,
        width: 510,
        pageBreak: 'after',
      },
      {
        style: {
          fillColor: '#00008B',
          color: 'white',
          bold: true,
          fontSize: 14,
        },
        table: {
          headerRows: 1,
          widths: ['*'],
          body: [
            [
              {
                text: [
                  `Scenario Report #${ScenarioReportData.scenarioId}`,
                  ' ',
                  ' ',
                  {
                    text: `  ${ScenarioReportData.approvalStatus}  `,
                    background: '#FFFFF0',
                    color: 'orange',
                    fontSize: 10,
                    bold: false,
                  },
                ],
              },
            ],
          ],
        },
        layout: 'noBorders',
      },
      {
        style: { fillColor: '#00008B', color: 'white' },
        table: {
          headerRows: 1,
          widths: [55, 50, 65, 110, 75, '*'],
          body: [
            [
              { text: 'Customer', style: 'tableHeader' },
              { text: 'Officer', style: 'tableHeader' },
              { text: 'Profitability', style: 'tableHeader' },
              { text: 'Profitability (1 year)', style: 'tableHeader' },
              { text: 'Amount Due', style: 'tableHeader' },
              { text: 'Amount Due (1 year)', style: 'tableHeader' },
            ],
            [
              ScenarioReportData.name,
              ScenarioReportData.officerName,
              ScenarioReportData.profitability,
              ScenarioReportData.futureProfitability,
              ScenarioReportData.amountDue,
              ScenarioReportData.futureAmountDue,
            ],
          ],
        },
        layout: 'noBorders',
      },
      '\n',
      {
        image: image3,
        width: 510,
      },
    ],
  };

  //@ts-ignore
  pdfMake.createPdf(docDefinition).open();
};
