import React from 'react';
import { Layout, Space } from 'antd';
import Hierarchy from '../hierarchy';
import Sidebar from '../sidebar';
import Header from '../header';
import { MainLayoutTypes } from '../../@types';
import './MainLayout.css';

const { Content } = Layout;

const MainLayout: React.FC<MainLayoutTypes> = ({ children }) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar />
      <Layout className='site-layout'>
        <Header />
        <Content
          style={{
            margin: '0 16px',
            width: '99%',
          }}
        >
          <Space
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'unset',
              marginTop: '1.5rem',
            }}
            direction='horizontal'
          >
            {children}
            <Hierarchy />
          </Space>
        </Content>
      </Layout>
    </Layout>
  );
};

export default React.memo(MainLayout);
