import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import approvalService from './approvalService';
import { resetAllAction } from '../../../../redux/actions';
import { ApprovalsInitialStateTypes } from '../../@types';

const initialState: ApprovalsInitialStateTypes = {
  selectedApprovals: null,
  selectedApprovalInfo: null,
  approvalStatus: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
};

export const selectApprovals = createAsyncThunk(
  'approvals/selectApprovals',
  (userId: string) => {
    return approvalService.selectApprovals(userId);
  }
);

export const approvalSlice = createSlice({
  name: 'approvals',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
    selectApprovalInfo: (state, action) => {
      state.isSuccess = true;
      state.selectedApprovalInfo = action.payload;
    },
    selectApprovalStatus: (state, action) => {
      state.isSuccess = true;
      state.approvalStatus = action.payload;
    },
    clearSelectedApprovals: (state) => {
      state.isSuccess = true;
      state.selectedApprovals = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(selectApprovals.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(selectApprovals.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.selectedApprovals = action.payload;
      })
      .addCase(resetAllAction, () => initialState);
  },
});

export const {
  reset,
  selectApprovalInfo,
  selectApprovalStatus,
  clearSelectedApprovals,
} = approvalSlice.actions;
export default approvalSlice.reducer;
