import { TRANSACTIONS, CUSTOMERS } from '../../../_mock/data';
import { TransactionTypes, CustomerTypes } from '../../../_mock/@types';

export const data = CUSTOMERS.map((customer: CustomerTypes) => ({
  customerName: customer.name,
  totalProfitability: customer.totalProfitability,
  amount: TRANSACTIONS.filter(
    (transaction: TransactionTypes) =>
      transaction.paid === true && transaction.customerId === customer.id
  ).reduce((prevValue, currValue) => prevValue + currValue.amount, 0),
}));
