import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../redux/app/hooks';
import {
  calculateTotalProfitabilityAmount,
  changeCurrencyFormat,
} from '../../utils';
import { LineChart, Line, Tooltip } from 'recharts';
import { Card, Typography, Space } from 'antd';
import { CUSTOMERS } from '../../../../_mock/data';
import { CustomerTypes } from '../../../../_mock/@types';
import { clientProfitabilityCardStyles, sharedStyles } from './styles';

const { Title } = Typography;

const totalClientProfitabilitySum =
  calculateTotalProfitabilityAmount(CUSTOMERS);

const ClientProfitabilityCard: React.FC = () => {
  const { selectedUser } = useAppSelector((state) => state.hierarchyTree);
  const {
    exchange: { exchangeRate, moneySign },
  } = useAppSelector((state) => state.currency);
  const [totalClientProfitability, setTotalClientProfitability] =
    useState<string>(
      changeCurrencyFormat(exchangeRate, moneySign, totalClientProfitabilitySum)
    );

  useEffect(() => {
    if (exchangeRate || moneySign) {
      const totalClientProfitability = changeCurrencyFormat(
        exchangeRate,
        moneySign,
        totalClientProfitabilitySum
      );
      setTotalClientProfitability(totalClientProfitability);
    }
  }, [exchangeRate, moneySign]);

  useEffect(() => {
    //@ts-ignore
    if (selectedUser?.officerId) {
      const [{ totalProfitability }] = CUSTOMERS.filter(
        (customer: CustomerTypes) => customer.id === selectedUser.id
      );
      const totalClientProfitability = changeCurrencyFormat(
        exchangeRate,
        moneySign,
        totalProfitability
      );
      setTotalClientProfitability(totalClientProfitability);
      return;
    }
    setTotalClientProfitability(
      changeCurrencyFormat(exchangeRate, moneySign, totalClientProfitabilitySum)
    );
  }, [selectedUser, exchangeRate, moneySign]);

  return (
    <Card
      hoverable
      //@ts-ignore
      style={clientProfitabilityCardStyles.cardStyles}
      cover={
        <>
          <Space
            direction='vertical'
            style={{
              marginTop: '5px',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <Title style={sharedStyles.titleStyles} level={4}>
              Total Client Profitability
            </Title>
            <Title style={sharedStyles.titleStyles} level={4}>
              {totalClientProfitability}
            </Title>
          </Space>
          <LineChart
            width={240}
            height={200}
            data={CUSTOMERS}
            margin={sharedStyles.chartStyles}
          >
            <Tooltip />
            <Line
              dot={false}
              type='monotone'
              dataKey='totalProfitability'
              stroke='black'
              strokeWidth={2}
            />
          </LineChart>
        </>
      }
    />
  );
};

export default ClientProfitabilityCard;
