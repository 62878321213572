export const transformFinalProducts = (
  array: any[],
  moneySign: string,
  exchangeRate: number
) => {
  const finalProducts = array.map((product: any) => ({
    ...product,
    loanAmount: product.loanAmount
      ? `${moneySign} ${Math.round(product.loanAmount * exchangeRate)}`
      : null,
    loanInterestRate: product.loanInterestRate
      ? `${product.loanInterestRate * 10}%`
      : null,
    serviceDiscountPercentage: product.serviceDiscountPercentage
      ? `${product.serviceDiscountPercentage * 10}%`
      : null,
  }));

  return finalProducts;
};
