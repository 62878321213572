import type { Moment } from 'moment';
import moment from 'moment';
import { EVENTS } from '../../../_mock/data';

const thirtyDaysInMiliseconds = 2592000;
const oneWeekInMiliseconds = 604800;
const todayInMiliseconds = Date.now();

const approvalsDueTime = (date: number) => {
  if (todayInMiliseconds - date > oneWeekInMiliseconds) {
    return true;
  }
  return false;
};

const transactionsDueTime = (date: number) => {
  if (todayInMiliseconds - date > thirtyDaysInMiliseconds) {
    return true;
  }
  return false;
};

export const eventList = EVENTS.map((event) => ({
  type: approvalsDueTime(event.date)
    ? 'warning'
    : transactionsDueTime(event.date)
    ? 'error'
    : 'success',
  content: event.description,
  isDue:
    event.caseType === 'approval'
      ? approvalsDueTime(event.date)
      : transactionsDueTime(event.date),
}));

export const getListData = (value: Moment) => {
  let listData;

  listData = EVENTS.filter(
    (event) => moment(event.date).dayOfYear() === value.dayOfYear()
  ).map((event) => ({
    type: approvalsDueTime(event.date)
      ? 'warning'
      : transactionsDueTime(event.date)
      ? 'error'
      : 'success',
    content: event.description,
    isDue:
      event.caseType === 'approval'
        ? approvalsDueTime(event.date)
        : transactionsDueTime(event.date),
    customerId: event.customerId,
    customerName: event.customerName,
  }));

  return listData;
};

export const getMonthData = (value: Moment) => {
  if (value.month() === 8) {
    return 1394;
  }
};
