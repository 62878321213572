import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { authService } from './authService';
import { InitialStateTypes, FormDataUserTypes, AuthUserTypes } from '../@types';
import { resetAllAction } from '../../../redux/actions';

const authUser: AuthUserTypes = JSON.parse(localStorage.getItem('authUser')!);

const initialState: InitialStateTypes = {
  authUser: authUser ? authUser : null,
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
};

export const loginUser = createAsyncThunk(
  'auth/login',
  (formData: FormDataUserTypes) => {
    return authService.login(formData);
  }
);

export const logoutUser = createAsyncThunk('auth/logout', () =>
  localStorage.removeItem('authUser')
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isLoading = false;
        state.authUser = action.payload;
      })
      .addCase(logoutUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.isSuccess = true;
        state.isLoading = false;
        state.authUser = null;
      })
      .addCase(resetAllAction, () => initialState);
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
