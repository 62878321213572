import { ScenariosTypes } from '../@types';
import { USERS } from './users';
import { PRODUCT_OFFERS } from './product-offers';

export const SCENARIOS: ScenariosTypes[] = [
  {
    id: '1',
    customerId: '1',
    officerId: '4',
    approvalStatus: 'pending',
    createdOn: Date.parse('12 Mar 2022'),
    createdBy: USERS[3],
    newProfitability: 5326,
    products: [PRODUCT_OFFERS[1], PRODUCT_OFFERS[2]],
  },
  {
    id: '2',
    customerId: '1',
    officerId: '4',
    approvalStatus: 'approved',
    createdOn: Date.parse('16 Mar 2022'),
    createdBy: USERS[3],
    newProfitability: 7856,
    products: [PRODUCT_OFFERS[0], PRODUCT_OFFERS[3]],
  },
  {
    id: '3',
    customerId: '1',
    officerId: '4',
    approvalStatus: 'rejected',
    createdOn: Date.parse('26 Mar 2022'),
    createdBy: USERS[3],
    newProfitability: 1235,
    products: [PRODUCT_OFFERS[1], PRODUCT_OFFERS[4]],
  },
  {
    id: '4',
    customerId: '2',
    officerId: '4',
    approvalStatus: 'pending',
    createdOn: Date.parse('12 Apr 2022'),
    createdBy: USERS[3],
    newProfitability: 4532,
    products: [PRODUCT_OFFERS[4], PRODUCT_OFFERS[5]],
  },
  {
    id: '5',
    customerId: '2',
    officerId: '4',
    approvalStatus: 'approved',
    createdOn: Date.parse('16 Apr 2022'),
    createdBy: USERS[3],
    newProfitability: 8973,
    products: [PRODUCT_OFFERS[2], PRODUCT_OFFERS[4]],
  },
  {
    id: '6',
    customerId: '2',
    officerId: '4',
    approvalStatus: 'rejected',
    createdOn: Date.parse('26 Apr 2022'),
    createdBy: USERS[3],
    newProfitability: 3345,
    products: [PRODUCT_OFFERS[1], PRODUCT_OFFERS[0]],
  },
  {
    id: '7',
    customerId: '3',
    officerId: '4',
    approvalStatus: 'pending',
    createdOn: Date.parse('12 Jun 2022'),
    createdBy: USERS[3],
    newProfitability: 4532,
    products: [PRODUCT_OFFERS[0], PRODUCT_OFFERS[4]],
  },
  {
    id: '8',
    customerId: '3',
    officerId: '4',
    approvalStatus: 'approved',
    createdOn: Date.parse('16 Jun 2022'),
    createdBy: USERS[3],
    newProfitability: 7894,
    products: [PRODUCT_OFFERS[4], PRODUCT_OFFERS[2]],
  },
  {
    id: '9',
    customerId: '3',
    officerId: '4',
    approvalStatus: 'rejected',
    createdOn: Date.parse('26 Jun 2022'),
    createdBy: USERS[3],
    newProfitability: 3562,
    products: [PRODUCT_OFFERS[4], PRODUCT_OFFERS[5]],
  },
  {
    id: '10',
    customerId: '4',
    officerId: '5',
    approvalStatus: 'pending',
    createdOn: Date.parse('12 Feb 2022'),
    createdBy: USERS[4],
    newProfitability: 3214,
    products: [PRODUCT_OFFERS[1], PRODUCT_OFFERS[4]],
  },
  {
    id: '11',
    customerId: '4',
    officerId: '5',
    approvalStatus: 'approved',
    createdOn: Date.parse('16 Feb 2022'),
    createdBy: USERS[4],
    newProfitability: 7654,
    products: [PRODUCT_OFFERS[0], PRODUCT_OFFERS[2]],
  },
  {
    id: '12',
    customerId: '4',
    officerId: '5',
    approvalStatus: 'rejected',
    createdOn: Date.parse('26 Feb 2022'),
    createdBy: USERS[4],
    newProfitability: 1234,
    products: [PRODUCT_OFFERS[0], PRODUCT_OFFERS[3]],
  },
  {
    id: '13',
    customerId: '5',
    officerId: '5',
    approvalStatus: 'pending',
    createdOn: Date.parse('12 Jul 2022'),
    createdBy: USERS[4],
    newProfitability: 3672,
    products: [PRODUCT_OFFERS[0], PRODUCT_OFFERS[1]],
  },
  {
    id: '14',
    customerId: '5',
    officerId: '5',
    approvalStatus: 'approved',
    createdOn: Date.parse('16 Jul 2022'),
    createdBy: USERS[4],
    newProfitability: 5643,
    products: [PRODUCT_OFFERS[4], PRODUCT_OFFERS[0]],
  },
  {
    id: '15',
    customerId: '5',
    officerId: '5',
    approvalStatus: 'rejected',
    createdOn: Date.parse('26 Jul 2022'),
    createdBy: USERS[4],
    newProfitability: 3212,
    products: [PRODUCT_OFFERS[0], PRODUCT_OFFERS[5]],
  },
  {
    id: '16',
    customerId: '6',
    officerId: '5',
    approvalStatus: 'pending',
    createdOn: Date.parse('12 Jul 2022'),
    createdBy: USERS[4],
    newProfitability: 3628,
    products: [PRODUCT_OFFERS[1], PRODUCT_OFFERS[4]],
  },
  {
    id: '17',
    customerId: '6',
    officerId: '5',
    approvalStatus: 'approved',
    createdOn: Date.parse('16 Jul 2022'),
    createdBy: USERS[4],
    newProfitability: 7876,
    products: [PRODUCT_OFFERS[2], PRODUCT_OFFERS[5]],
  },
  {
    id: '18',
    customerId: '6',
    officerId: '5',
    approvalStatus: 'rejected',
    createdOn: Date.parse('26 Jul 2022'),
    createdBy: USERS[4],
    newProfitability: 4322,
    products: [PRODUCT_OFFERS[1], PRODUCT_OFFERS[5]],
  },
  {
    id: '19',
    customerId: '7',
    officerId: '6',
    approvalStatus: 'pending',
    createdOn: Date.parse('12 Jun 2022'),
    createdBy: USERS[5],
    newProfitability: 6453,
    products: [PRODUCT_OFFERS[2], PRODUCT_OFFERS[1]],
  },
  {
    id: '20',
    customerId: '7',
    officerId: '6',
    approvalStatus: 'approved',
    createdOn: Date.parse('16 Jun 2022'),
    createdBy: USERS[5],
    newProfitability: 3215,
    products: [PRODUCT_OFFERS[4], PRODUCT_OFFERS[0]],
  },
  {
    id: '21',
    customerId: '7',
    officerId: '6',
    approvalStatus: 'rejected',
    createdOn: Date.parse('26 Jun 2022'),
    createdBy: USERS[5],
    newProfitability: 321,
    products: [PRODUCT_OFFERS[5], PRODUCT_OFFERS[0]],
  },
  {
    id: '22',
    customerId: '8',
    officerId: '6',
    approvalStatus: 'pending',
    createdOn: Date.parse('12 Jan 2022'),
    createdBy: USERS[5],
    newProfitability: 6732,
    products: [PRODUCT_OFFERS[1], PRODUCT_OFFERS[4]],
  },
  {
    id: '23',
    customerId: '8',
    officerId: '6',
    approvalStatus: 'approved',
    createdOn: Date.parse('16 Jan 2022'),
    createdBy: USERS[5],
    newProfitability: 9877,
    products: [PRODUCT_OFFERS[4], PRODUCT_OFFERS[2]],
  },
  {
    id: '24',
    customerId: '8',
    officerId: '6',
    approvalStatus: 'rejected',
    createdOn: Date.parse('26 Jan 2022'),
    createdBy: USERS[5],
    newProfitability: 3321,
    products: [PRODUCT_OFFERS[5], PRODUCT_OFFERS[1]],
  },
  {
    id: '25',
    customerId: '9',
    officerId: '6',
    approvalStatus: 'pending',
    createdOn: Date.parse('12 Apr 2022'),
    createdBy: USERS[5],
    newProfitability: 4325,
    products: [PRODUCT_OFFERS[4], PRODUCT_OFFERS[0]],
  },
  {
    id: '26',
    customerId: '9',
    officerId: '6',
    approvalStatus: 'approved',
    createdOn: Date.parse('16 Apr 2022'),
    createdBy: USERS[5],
    newProfitability: 6547,
    products: [PRODUCT_OFFERS[1], PRODUCT_OFFERS[3]],
  },
  {
    id: '27',
    customerId: '9',
    officerId: '6',
    approvalStatus: 'rejected',
    createdOn: Date.parse('26 Apr 2022'),
    createdBy: USERS[5],
    newProfitability: 2213,
    products: [PRODUCT_OFFERS[4], PRODUCT_OFFERS[2]],
  },
  {
    id: '28',
    customerId: '10',
    officerId: '7',
    approvalStatus: 'pending',
    createdOn: Date.parse('12 Mar 2022'),
    createdBy: USERS[6],
    newProfitability: 3654,
    products: [PRODUCT_OFFERS[3], PRODUCT_OFFERS[1]],
  },
  {
    id: '29',
    customerId: '10',
    officerId: '7',
    approvalStatus: 'approved',
    createdOn: Date.parse('16 Mar 2022'),
    createdBy: USERS[6],
    newProfitability: 5543,
    products: [PRODUCT_OFFERS[4], PRODUCT_OFFERS[5]],
  },
  {
    id: '30',
    customerId: '10',
    officerId: '7',
    approvalStatus: 'rejected',
    createdOn: Date.parse('26 Mar 2022'),
    createdBy: USERS[6],
    newProfitability: 1234,
    products: [PRODUCT_OFFERS[5], PRODUCT_OFFERS[0]],
  },
  {
    id: '31',
    customerId: '11',
    officerId: '7',
    approvalStatus: 'pending',
    createdOn: Date.parse('12 Feb 2022'),
    createdBy: USERS[6],
    newProfitability: 4321,
    products: [PRODUCT_OFFERS[1], PRODUCT_OFFERS[4]],
  },
  {
    id: '32',
    customerId: '11',
    officerId: '7',
    approvalStatus: 'approved',
    createdOn: Date.parse('16 Feb 2022'),
    createdBy: USERS[6],
    newProfitability: 8769,
    products: [PRODUCT_OFFERS[2], PRODUCT_OFFERS[1]],
  },
  {
    id: '33',
    customerId: '11',
    officerId: '7',
    approvalStatus: 'rejected',
    createdOn: Date.parse('26 Feb 2022'),
    createdBy: USERS[6],
    newProfitability: 2356,
    products: [PRODUCT_OFFERS[5], PRODUCT_OFFERS[3]],
  },
  {
    id: '34',
    customerId: '12',
    officerId: '7',
    approvalStatus: 'pending',
    createdOn: Date.parse('12 Feb 2022'),
    createdBy: USERS[6],
    newProfitability: 5436,
    products: [PRODUCT_OFFERS[2], PRODUCT_OFFERS[5]],
  },
  {
    id: '35',
    customerId: '12',
    officerId: '7',
    approvalStatus: 'approved',
    createdOn: Date.parse('16 Feb 2022'),
    createdBy: USERS[6],
    newProfitability: 9875,
    products: [PRODUCT_OFFERS[2], PRODUCT_OFFERS[4]],
  },
  {
    id: '36',
    customerId: '12',
    officerId: '7',
    approvalStatus: 'rejected',
    createdOn: Date.parse('26 Feb 2022'),
    createdBy: USERS[6],
    newProfitability: 3321,
    products: [PRODUCT_OFFERS[0], PRODUCT_OFFERS[5]],
  },
];
