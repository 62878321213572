import { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../../../redux/app/hooks';
import { selectNewProduct } from '../../redux/scenarios/scenarioSlice';
import { Card, Table, Tag, Button, Space } from 'antd';
import {
  EyeTwoTone,
  EditTwoTone,
  DeleteTwoTone,
  PlusCircleTwoTone,
} from '@ant-design/icons';
import ProductModal from '../product-modal';
import { transformProducts, transformCustomerInfoData } from '../../utils';
import { ProductOfferTypes, UserTypes } from '../../../../_mock/@types';
import { USERS } from '../../../../_mock/data';
import type { ColumnsType } from 'antd/es/table';
import Spinner from '../../../../components/spinner';
import ActionButtons from '../action-buttons/ActionButtons';
import { NewProductsTablePropTypes } from '../../@types';

const NewProductsTable: React.FC<NewProductsTablePropTypes> = ({
  showActionButtons,
}) => {
  const [approvalModal, setApprovalModal] = useState<boolean>(false);
  const [allNewProducts, setAllNewProducts] = useState<
    ProductOfferTypes[] | null
  >(null);
  const { selectedScenario, isLoading } = useAppSelector(
    (state) => state.scenarios
  );
  const {
    exchange: { exchangeRate, moneySign },
  } = useAppSelector((state) => state.currency);
  const { authUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedScenario) {
      const newProducts: ProductOfferTypes[] = transformProducts(
        selectedScenario,
        exchangeRate,
        moneySign,
        false
      );
      setAllNewProducts(newProducts);
    }
    // eslint-disable-next-line
  }, [selectedScenario]);

  if (isLoading) return <Spinner />;

  const authUserId = authUser?.id;
  const isDisabled = allNewProducts?.every(
    (product) => product.approvalStatus === 'approved'
  );

  const officerUsername = USERS.find(
    (user: UserTypes) => selectedScenario[0].officerId === user.id
  );

  const customerInfoData = transformCustomerInfoData(
    selectedScenario,
    officerUsername,
    moneySign,
    exchangeRate
  );

  const handleRemoveProduct = (productId: string) => {
    const { id: userCreatedScenarioId } = selectedScenario.createdBy;

    if (userCreatedScenarioId !== authUserId) {
      throw window.alert('Sorry, you are not authorized!');
    }

    const remainingProducts = allNewProducts?.filter(
      (product) => product.id !== productId
    );

    setAllNewProducts(remainingProducts!);
  };

  const handleOpenModal = () => {
    setApprovalModal(true);
  };

  const handleCloseModal = () => {
    setApprovalModal(false);
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      filters: allNewProducts?.map((product) => ({
        text: product.name,
        value: product.name,
      })),
      onFilter: (value: any, record) => record.name.includes(value),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (type: string) => {
        let color: string = 'success';
        if (type === 'loan') {
          color = 'orange';
        }
        return (
          <Tag color={color} key={type}>
            {type}
          </Tag>
        );
      },
      filters: [
        {
          text: 'loan',
          value: 'loan',
        },
        {
          text: 'service',
          value: 'service',
        },
      ],
      onFilter: (value: any, record) => record.type.includes(value),
    },
    {
      title: 'Status',
      dataIndex: 'approvalStatus',
      key: 'approvalStatus',
      render: (status: string) => {
        let color: string = 'success';
        if (status === 'pending') {
          color = 'orange';
        }
        if (status === 'rejected') {
          color = 'red';
        }
        return (
          <Tag color={color} key={status}>
            {status}
          </Tag>
        );
      },
      filters: [
        {
          text: 'pending',
          value: 'pending',
        },
        {
          text: 'approved',
          value: 'approved',
        },
        {
          text: 'rejected',
          value: 'rejected',
        },
      ],
      onFilter: (value: any, record) => record.approvalStatus.includes(value),
    },
    {
      title: 'Service Discount Percentage',
      dataIndex: 'serviceDiscountPercentage',
      key: 'serviceDiscountPercentage',
      render: (value) => (value === null ? <>-</> : value),
    },
    {
      title: 'Loan Amount',
      dataIndex: 'loanAmount',
      key: 'loanAmount',
      sorter: {
        compare: (a, b) => {
          const A = parseInt(a.amount.split(' ')[1]);
          const B = parseInt(b.amount.split(' ')[1]);
          return A - B;
        },
      },
    },
    {
      title: 'Loan Interest Rate',
      dataIndex: 'loanInterestRate',
      key: 'loanInterestRate',
      sorter: {
        compare: (a, b) => {
          const A = parseInt(a.loanInterestRate.split('%')[0]);
          const B = parseInt(b.loanInterestRate.split('%')[0]);
          return A - B;
        },
      },
    },
    {
      title: 'Actions',
      key: 'action',
      render: (_: any) =>
        _.approvalStatus !== 'approved' && (
          <Space size='small'>
            <Button
              ghost={true}
              shape='circle'
              icon={<EyeTwoTone style={{ fontSize: '22px' }} />}
              onClick={() => ProductModal('view')}
            />
            <Button
              disabled={selectedScenario.isViewOnly}
              ghost={true}
              shape='circle'
              icon={
                <EditTwoTone
                  style={{ fontSize: '22px' }}
                  twoToneColor='#00A300'
                />
              }
              onClick={() => ProductModal('edit')}
            />
            <Button
              disabled={selectedScenario.isViewOnly}
              ghost={true}
              shape='circle'
              icon={
                <DeleteTwoTone
                  style={{ fontSize: '22px' }}
                  twoToneColor='#FF0000'
                />
              }
              onClick={() => handleRemoveProduct(_.id)}
            />
          </Space>
        ),
    },
  ];

  return (
    <>
      <Card
        extra={
          <Button
            disabled={selectedScenario.isViewOnly}
            type='primary'
            icon={<PlusCircleTwoTone />}
            onClick={() => ProductModal('new')}
          />
        }
        style={{ marginTop: '3.5rem' }}
        headStyle={{
          backgroundColor: 'blue',
          color: 'white',
        }}
        title='New Products'
        bordered={false}
      >
        {allNewProducts && (
          <Table
            id='all-new-products-table'
            columns={columns}
            dataSource={allNewProducts}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => dispatch(selectNewProduct(record)),
              };
            }}
          />
        )}
      </Card>
      {showActionButtons && (
        <ActionButtons
          isDisabled={isDisabled}
          isVisible={approvalModal}
          setApprovalModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          ScenarioReportData={customerInfoData}
        />
      )}
    </>
  );
};

export default NewProductsTable;
