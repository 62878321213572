import { TRANSACTIONS } from '../../../_mock/data';
import { TransactionTypes } from '../../../_mock/@types';

export const transformCustomerInfoData = (
  selectedScenario: any,
  officerUsername: any,
  moneySign: string,
  exchangeRate: number
) => {
  return {
    name: selectedScenario[0].name,
    profitability: `${moneySign} ${(
      selectedScenario[0].totalProfitability * exchangeRate
    ).toFixed(2)}`,
    productsOwned: selectedScenario.products.filter(
      (product: any) => product.isActual === true
    ).length,
    transactionsCompleted: TRANSACTIONS.filter(
      (transaction: TransactionTypes) =>
        transaction.customerName === selectedScenario[0].name &&
        transaction.paid === true
    ).length,
    transactionsPending: TRANSACTIONS.filter(
      (transaction: TransactionTypes) =>
        transaction.customerName === selectedScenario[0].name &&
        transaction.paid === false
    ).length,
    amountDue: `${moneySign} ${(
      selectedScenario[0].totalProfitability *
      0.4 *
      exchangeRate
    ).toFixed(2)}`,
    officerName: officerUsername?.name,
    futureProfitability: `${moneySign} ${(
      parseFloat(selectedScenario.newProfitability.split(' ')[1]) * exchangeRate
    ).toFixed(2)}`,
    futureAmountDue: `${moneySign} ${(
      selectedScenario[0].totalProfitability *
      0.1 *
      exchangeRate
    ).toFixed(2)}`,
    scenarioId: selectedScenario.id,
    approvalStatus:
      selectedScenario.approvalStatus === 'pending' ? 'Needs Approval' : null,
  };
};
