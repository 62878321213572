import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/app/hooks';
import { clearSelectedApprovals } from '../../redux/approvals/approvalSlice';
import { Typography, Space, Switch } from 'antd';
import DownloadButton from '../../../../components/download-button';
import RequestedApprovalsTable from '../../components/requested-approvals/RequestedApprovalsTable';

const { Title } = Typography;

const ApprovalsPage = () => {
  const [approvalsTableData, setApprovalsTableData] = useState<any[] | null>(
    null
  );
  const [approvalsTableId, setApprovalsTableId] = useState<string | null>(null);
  const { selectedApprovals } = useAppSelector((state) => state.approvals);
  const dispatch = useAppDispatch();

  const handleApprovalsTableData = (approvals: any[], elementId: string) => {
    setApprovalsTableData(approvals);
    setApprovalsTableId(elementId);
  };

  const showAllApprovals = () => {
    dispatch(clearSelectedApprovals());
  };

  return (
    <div style={{ width: '105%' }}>
      <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title style={{ marginBottom: '1.5rem' }} level={2}>
          Requested Approvals
        </Title>
        <Space>
          {selectedApprovals ? 'Show Selected' : 'Show All'}
          <Switch
            checked={selectedApprovals ? true : false}
            onClick={showAllApprovals}
          />
          <DownloadButton
            excelData={approvalsTableData}
            elementId={approvalsTableId}
          />
        </Space>
      </Space>

      <RequestedApprovalsTable
        handleApprovalsTableData={handleApprovalsTableData}
      />
    </div>
  );
};

export default ApprovalsPage;
