import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/app/hooks';
import { useSelectApprovals } from '../../hooks';
import { selectApprovalInfo } from '../../redux/approvals/approvalSlice';
import ApprovalModal from '../modal/ApprovalModal';
import { Space, Table, Button, message } from 'antd';
import {
  EyeTwoTone,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from '@ant-design/icons';
import { APPROVALS, USERS, CUSTOMERS, PRODUCTS } from '../../../../_mock/data';
import { ApprovalTypes } from '../../../../_mock/@types';
import type { ColumnsType } from 'antd/es/table';
import { addKeyPropInData } from '../../utils';
import { RequestApprovalsTablePropTypes } from '../../@types';

const mockApprovals = addKeyPropInData(APPROVALS);

const RequestedApprovalsTable: React.FC<RequestApprovalsTablePropTypes> = ({
  handleApprovalsTableData,
}) => {
  // eslint-disable-next-line
  const [allApprovals, setAllApprovals] =
    useState<ApprovalTypes[]>(mockApprovals);
  const [selectedApprovals, setSelectedApprovals] = useState<
    ApprovalTypes[] | null
  >(null);
  const { selectedApprovals: approvals, approvalStatus } = useAppSelector(
    (state) => state.approvals
  );
  const { selectedUser } = useAppSelector((state) => state.hierarchyTree);
  const dispatch = useAppDispatch();
  useSelectApprovals(selectedUser);

  useEffect(() => {
    if (selectedApprovals) {
      return handleApprovalsTableData(selectedApprovals, 'approvals - table');
    }
    if (allApprovals) {
      return handleApprovalsTableData(allApprovals, 'approvals-table');
    }
    // eslint-disable-next-line
  }, [selectedApprovals, allApprovals]);

  useEffect(() => {
    if (approvals) {
      const data = addKeyPropInData(approvals);
      setSelectedApprovals(data);
    }
    if (!approvals) {
      setSelectedApprovals(null);
    }
  }, [approvals]);

  useEffect(() => {
    if (approvalStatus?.id) {
      filterApprovals(approvalStatus.id);
    }
    // eslint-disable-next-line
  }, [approvalStatus]);

  const filterApprovals = (
    approvalId: string,
    status?: 'approved' | 'rejected'
  ) => {
    if (selectedApprovals) {
      const approvals = selectedApprovals.filter(
        (approval: ApprovalTypes) => approval.id !== approvalId
      );

      const data = addKeyPropInData(approvals);
      setSelectedApprovals(data);
      return;
    }
    const approvals = allApprovals.filter(
      (approval: ApprovalTypes) => approval.id !== approvalId
    );

    const data = addKeyPropInData(approvals);

    setAllApprovals(data);

    if (status === 'approved') {
      message.success('Approved!');
    }

    if (status === 'rejected') {
      message.error('Rejected!');
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Requested By',
      dataIndex: 'userName',
      key: 'userName',
      filters: USERS.map((user) => ({
        text: user.name,
        value: user.name,
      })),
      onFilter: (value: any, record) => record.userName.includes(value),
      sorter: (a, b) => a.userName.length - b.userName.length,
    },
    {
      title: 'Customer',
      dataIndex: 'customerName',
      key: 'customerName',
      filters: CUSTOMERS.map((customer) => ({
        text: customer.name,
        value: customer.name,
      })),
      onFilter: (value: any, record) => record.customerName.includes(value),
      sorter: (a, b) => a.customerName.length - b.customerName.length,
    },
    {
      title: 'Product',
      dataIndex: 'productName',
      key: 'productName',
      filters: PRODUCTS.map((product) => ({
        text: product.name,
        value: product.name,
      })),
      onFilter: (value: any, record) => record.productName.includes(value),
      sorter: (a, b) => a.productName.length - b.productName.length,
    },
    {
      title: 'Request Reason',
      dataIndex: 'requestReason',
      key: 'requestReason',
      filters: selectedApprovals
        ? selectedApprovals.map((approval) => ({
            text: approval.requestReason,
            value: approval.requestReason,
          }))
        : allApprovals.map((approval) => ({
            text: approval.requestReason,
            value: approval.requestReason,
          })),
      onFilter: (value: any, record) => record.requestReason.includes(value),
      sorter: (a, b) => a.requestReason.length - b.requestReason.length,
    },
    {
      title: 'Expiring Date',
      dataIndex: 'expirationDate',
      key: 'expirationDate',
      sorter: {
        compare: (a, b) => {
          const A = a.expirationDate.replaceAll('/', '-');
          const B = b.expirationDate.replaceAll('/', '-');
          return Date.parse(A) - Date.parse(B);
        },
      },
    },
    {
      title: 'Actions',
      key: 'action',
      render: (_: any) => (
        <Space size='small'>
          <Button
            ghost={true}
            shape='circle'
            icon={<EyeTwoTone style={{ fontSize: '22px' }} />}
            onClick={() => ApprovalModal()}
          />

          <Button
            ghost={true}
            shape='circle'
            icon={
              <CheckCircleTwoTone
                style={{ fontSize: '22px' }}
                twoToneColor='#32CD32'
              />
            }
            onClick={() => filterApprovals(_.id, 'approved')}
          />
          <Button
            ghost={true}
            shape='circle'
            icon={
              <CloseCircleTwoTone
                style={{ fontSize: '22px' }}
                twoToneColor='#FF0000'
              />
            }
            onClick={() => filterApprovals(_.id, 'rejected')}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table
        id='approvals-table'
        columns={columns}
        dataSource={selectedApprovals ? selectedApprovals : allApprovals}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              dispatch(selectApprovalInfo(record));
            },
          };
        }}
      />
    </>
  );
};

export default RequestedApprovalsTable;
