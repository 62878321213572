import { Modal } from 'antd';
import { ProductModalContent } from './ProductModalContent';

const ProductModal = (modalType: string) => {
  let modal = Modal;

  if (modalType === 'new') {
    modal.info({
      title: 'New Product',
      content: ProductModalContent(true, true),
      width: '60%',
      icon: null,
    });
  }

  if (modalType === 'view') {
    modal.info({
      title: 'View Product',
      content: ProductModalContent(false, false),
      width: '60%',
      icon: null,
    });
  }

  if (modalType === 'edit') {
    modal.success({
      title: 'Edit Product',
      content: ProductModalContent(false, true),
      width: '60%',
      icon: null,
    });
  }
};

export default ProductModal;
