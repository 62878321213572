import { useAppSelector } from '../../../../redux/app/hooks';
import { Typography } from 'antd';
import ProductListItem from './ProductListItem';
import Spinner from '../../../../components/spinner';
import { transformFinalProducts } from '../../data';
import { ProductListPropTypes } from '../../@types';

const { Title } = Typography;

const ProductList: React.FC<ProductListPropTypes> = ({ isEdit }) => {
  const {
    selectedCustomer: { products },
    isLoading,
  } = useAppSelector((state) => state.management);
  const {
    exchange: { exchangeRate, moneySign },
  } = useAppSelector((state) => state.currency);

  if (isLoading) return <Spinner />;

  const finalProducts = transformFinalProducts(
    products,
    moneySign,
    exchangeRate
  );

  return (
    <>
      {finalProducts && (
        <>
          <Title level={4}>Product List</Title>
          {finalProducts.map((product: any, index: number) => (
            <ProductListItem key={index} product={product} isEdit={isEdit} />
          ))}
        </>
      )}
    </>
  );
};

export default ProductList;
