import { useAppSelector } from '../../../../redux/app/hooks';
import { Descriptions, Typography } from 'antd';

const { Title } = Typography;

const CustomerInfo: React.FC = () => {
  const { selectedCustomer } = useAppSelector((state) => state.management);
  return (
    <>
      {selectedCustomer && (
        <>
          <Title level={4}>Customer Info</Title>
          <Descriptions style={{ marginBottom: '4rem' }} bordered>
            <Descriptions.Item label='Name'>
              {selectedCustomer!.customerName}
            </Descriptions.Item>
            <Descriptions.Item label='Total Profitability'>
              {selectedCustomer!.totalProfitability}
            </Descriptions.Item>
            <Descriptions.Item label='Products Owned'>
              {selectedCustomer!.productsOwned}
            </Descriptions.Item>
            <Descriptions.Item label='Transactions Complete'>
              {selectedCustomer!.completedTransactions}
            </Descriptions.Item>
            <Descriptions.Item label='Transactions Pending' span={2}>
              {selectedCustomer!.pendingTransactions}
            </Descriptions.Item>
            <Descriptions.Item label='Amount Due'>
              {selectedCustomer!.totalProfitability}
            </Descriptions.Item>
            <Descriptions.Item label='Officer Name' span={2}>
              {selectedCustomer!.officerName}
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
    </>
  );
};

export default CustomerInfo;
