import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/app/hooks';
import { selectCustomer } from '../../redux/management/managementSlice';
import { Space, Table, Button } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { EyeTwoTone, EditTwoTone } from '@ant-design/icons';
import CustomerModal from '../customer-info-modal';
import { transformFinalData } from '../../data';
import { CUSTOMERS, USERS } from '../../../../_mock/data';
import { CustomerTypes, UserTypes } from '../../../../_mock/@types';
import { FinalDataTypes } from '../../@types';
import { DataType } from '../../../dashboard/@types';

const CustomersTable: React.FC = () => {
  const [tableData, setTableData] = useState<FinalDataTypes[] | null>(null);
  const { selectedUser } = useAppSelector((state) => state.hierarchyTree);
  const {
    exchange: { exchangeRate, moneySign },
  } = useAppSelector((state) => state.currency);
  const dispatch = useAppDispatch();

  useEffect(() => {
    //@ts-ignore
    if (selectedUser?.category) {
      const tableData = finalData.filter(
        (data: FinalDataTypes) => data.officerName === selectedUser.name
      );
      setTableData(tableData);
    }
    // eslint-disable-next-line
  }, [selectedUser]);

  useEffect(() => {
    //@ts-ignore
    if (!selectedUser) {
      setTableData(null);
    }
    // eslint-disable-next-line
  }, [selectedUser]);

  const finalData = transformFinalData(moneySign, exchangeRate);

  const columns: ColumnsType<FinalDataTypes> = [
    {
      title: 'Name',
      dataIndex: 'customerName',
      key: 'customer',
      filters: CUSTOMERS.map((customer: CustomerTypes) => ({
        text: customer.name,
        value: customer.name,
      })),
      onFilter: (value: any, record) => record.customerName.includes(value),
      sorter: (a, b) => a.customerName.length - b.customerName.length,
    },
    {
      title: 'Total Profitabilit',
      dataIndex: 'totalProfitability',
      key: 'totalProfitability',
      sorter: (a, b) => {
        const A = parseInt(a.totalProfitability.split(' ')[1]);
        const B = parseInt(b.totalProfitability.split(' ')[1]);
        return A - B;
      },
    },
    {
      title: 'Products Owned',
      children: [
        {
          title: 'Loans',
          dataIndex: 'loans',
          key: 'loans',
          sorter: (a, b) => a.loans - b.loans,
        },
        {
          title: 'Services',
          dataIndex: 'services',
          key: 'services',
          sorter: (a, b) => a.services - b.services,
        },
      ],
    },
    {
      title: 'Transactions',
      children: [
        {
          title: 'Completed',
          dataIndex: 'completedTransactions',
          key: 'completed',
          sorter: (a, b) => a.completedTransactions - b.completedTransactions,
        },
        {
          title: 'Pending',
          dataIndex: 'pendingTransactions',
          key: 'pendingTransactions',
          sorter: (a, b) => a.pendingTransactions - b.pendingTransactions,
        },
      ],
    },
    {
      title: 'Amount Due',
      dataIndex: 'amountDue',
      key: 'amountDue',
      sorter: (a, b) => {
        const A = parseInt(a.totalProfitability.split(' ')[1]);
        const B = parseInt(b.totalProfitability.split(' ')[1]);
        return A - B;
      },
    },
    {
      title: 'Officer Name',
      dataIndex: 'officerName',
      key: 'officerName',
      filters: USERS.map((user: UserTypes) => ({
        text: user.name,
        value: user.name,
      })),
      onFilter: (value: any, record) => record.officerName.includes(value),
      sorter: (a, b) => a.officerName.length - b.officerName.length,
    },
    {
      title: 'Actions',
      key: 'action',
      render: (_: any) => (
        <Space size='small'>
          <Button
            ghost={true}
            shape='circle'
            icon={<EyeTwoTone style={{ fontSize: '22px' }} />}
            onClick={() => CustomerModal('view')}
          />
          <Button
            ghost={true}
            shape='circle'
            icon={
              <EditTwoTone
                style={{ fontSize: '22px' }}
                twoToneColor='#00A300'
              />
            }
            onClick={() => CustomerModal('edit')}
          />
        </Space>
      ),
    },
  ];

  const onChange: TableProps<DataType>['onChange'] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  return (
    <Table
      dataSource={tableData ? tableData : finalData}
      columns={columns}
      onChange={() => onChange}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            dispatch(selectCustomer(record));
          },
        };
      }}
    />
  );
};

export default CustomersTable;
