import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { Card } from 'antd';
import { chartData } from '../../data';

const TransactionsChart = () => {
  return (
    <Card
      headStyle={{ backgroundColor: 'blue', color: 'white' }}
      title='Transactions Completed / Pending'
      bordered={false}
    >
      <BarChart
        width={400}
        height={300}
        data={chartData}
        margin={{
          top: 5,
          right: 10,
          left: -15,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='customerName' />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey='completedTransactionsAmount' fill='#8884d8' />
        <Bar dataKey='pendingTransactionsAmount' fill='#82ca9d' />
      </BarChart>
    </Card>
  );
};

export default TransactionsChart;
