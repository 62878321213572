import { Modal } from 'antd';
import ReviewScenarioModalProvider from './ReviewScenarioModalProvider';

const ReviewScenarioModal = () => {
  let modal = Modal;

  modal.info({
    title: 'Review Scenario',
    content: ReviewScenarioModalProvider(),
    width: '80%',
    maskClosable: true,
    centered: true,
    icon: null,
    okButtonProps: { style: { display: 'none' } },
  });
};

export default ReviewScenarioModal;
