import Router from './routes';
import 'antd/dist/antd.min.css';
import './App.css';

function App() {
  return (
    <div className='App'>
      <Router />
    </div>
  );
}

export default App;
