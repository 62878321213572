import { useState } from 'react';
import { Typography, Space } from 'antd';
import EventCalendar from '../../components/calendar';
import DownloadButton from '../../../../components/download-button';

const { Title } = Typography;

const EventViewerPage: React.FC = () => {
  const [eventsTableData, setEventsTableData] = useState<any[] | null>(null);
  const [eventsTableId, setEventsTableId] = useState<string | null>(null);

  const handleEventsTableData = (events: any[], elementId: string) => {
    setEventsTableData(events);
    setEventsTableId(elementId);
  };

  return (
    <div
      id='event-viewer'
      style={{
        marginBottom: '5rem',
        width: '120vh',
      }}
    >
      <Space
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Title style={{ marginBottom: '1.5rem' }} level={2}>
          Requested Approvals
        </Title>
        <DownloadButton excelData={eventsTableData} elementId={eventsTableId} />
      </Space>
      <EventCalendar handleEventsTableData={handleEventsTableData} />
    </div>
  );
};

export default EventViewerPage;
