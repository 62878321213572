import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import hierarchyTreeService from './hierarchyTreeService';
import { resetAllAction } from '../actions';
import { HierarchyTreeInitialStateTypes } from '../../@types';

const initialState: HierarchyTreeInitialStateTypes = {
  selectedUser: null,
  searchFilter: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
};

export const selectUser = createAsyncThunk(
  'hierarchyTree/selectUser',
  (userName: string) => {
    return hierarchyTreeService.selectUser(userName);
  }
);

export const findUserOrCustomer = createAsyncThunk(
  'hierarchyTree/findUserOrCustomer',
  (searchInput: string) => {
    return hierarchyTreeService.findUserOrCustomer(searchInput);
  }
);

export const hierarchyTreeSlice = createSlice({
  name: 'hierarchyTree',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
    clearSearch: (state) => {
      state.searchFilter = null;
      state.selectedUser = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(selectUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(selectUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.selectedUser = action.payload;
      })
      .addCase(findUserOrCustomer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(findUserOrCustomer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        //@ts-ignore
        state.searchFilter = action.payload;
      })
      .addCase(findUserOrCustomer.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(resetAllAction, () => initialState);
  },
});

export const { reset, clearSearch } = hierarchyTreeSlice.actions;
export default hierarchyTreeSlice.reducer;
