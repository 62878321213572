import { EventTypes } from '../@types';

export const EVENTS: EventTypes[] = [
  {
    id: '1',
    description: 'approval request',
    customerId: '1',
    customerName: 'max',
    caseType: 'approval',
    date: Date.parse('01 Jan 2022'),
  },
  {
    id: '2',
    description: 'transaction request',
    customerId: '1',
    customerName: 'max',
    caseType: 'product',
    date: Date.parse('05 Feb 2022'),
  },
  {
    id: '3',
    description: 'approval request',
    customerId: '2',
    customerName: 'josephine',
    caseType: 'approval',
    date: Date.parse('21 Mar 2022'),
  },
  {
    id: '4',
    description: 'transaction request',
    customerId: '2',
    customerName: 'josephine',
    caseType: 'product',
    date: Date.parse('12 Apr 2022'),
  },
  {
    id: '5',
    description: 'approval request',
    customerId: '3',
    customerName: 'dereck',
    caseType: 'approval',
    date: Date.parse('01 Jul 2022'),
  },
  {
    id: '6',
    description: 'transaction request',
    customerId: '3',
    customerName: 'dereck',
    caseType: 'product',
    date: Date.parse('26 Jan 2022'),
  },
  {
    id: '7',
    description: 'approval request',
    customerId: '4',
    customerName: 'devon',
    caseType: 'approval',
    date: Date.parse('27 Feb 2022'),
  },
  {
    id: '8',
    description: 'transaction request',
    customerId: '4',
    customerName: 'devon',
    caseType: 'product',
    date: Date.parse('25 May 2022'),
  },
  {
    id: '9',
    description: 'approval request',
    customerId: '5',
    customerName: 'simon',
    caseType: 'approval',
    date: Date.parse('11 Mar 2022'),
  },
  {
    id: '10',
    description: 'transaction request',
    customerId: '5',
    customerName: 'simon',
    caseType: 'product',
    date: Date.parse('30 Jun 2022'),
  },
  {
    id: '11',
    description: 'approval request',
    customerId: '6',
    customerName: 'dolly',
    caseType: 'approval',
    date: Date.parse('01 Apr 2022'),
  },
  {
    id: '12',
    description: 'transaction request',
    customerId: '6',
    customerName: 'dolly',
    caseType: 'product',
    date: Date.parse('01 Jul 2022'),
  },
  {
    id: '13',
    description: 'approval request',
    customerId: '7',
    customerName: 'brad',
    caseType: 'approval',
    date: Date.parse('22 May 2022'),
  },
  {
    id: '14',
    description: 'transaction request',
    customerId: '7',
    customerName: 'brad',
    caseType: 'product',
    date: Date.parse('15 Apr 2022'),
  },
  {
    id: '15',
    description: 'approval request',
    customerId: '8',
    customerName: 'keith',
    caseType: 'approval',
    date: Date.parse('13 Jun 2022'),
  },
  {
    id: '16',
    description: 'transaction request',
    customerId: '8',
    customerName: 'keith',
    caseType: 'product',
    date: Date.parse('26 Apr 2022'),
  },
  {
    id: '17',
    description: 'approval request',
    customerId: '9',
    customerName: 'helen',
    caseType: 'approval',
    date: Date.parse('18 Jul 2022'),
  },
  {
    id: '18',
    description: 'transaction request',
    customerId: '9',
    customerName: 'helen',
    caseType: 'product',
    date: Date.parse('27 Jan 2022'),
  },
  {
    id: '19',
    description: 'approval request',
    customerId: '10',
    customerName: 'anna',
    caseType: 'approval',
    date: Date.parse('24 Mar 2022'),
  },
  {
    id: '20',
    description: 'transaction request',
    customerId: '10',
    customerName: 'anna',
    caseType: 'product',
    date: Date.parse('13 Feb 2022'),
  },
  {
    id: '21',
    description: 'approval request',
    customerId: '11',
    customerName: 'logan',
    caseType: 'approval',
    date: Date.parse('29 Jul 2022'),
  },
  {
    id: '22',
    description: 'transaction request',
    customerId: '11',
    customerName: 'logan',
    caseType: 'product',
    date: Date.parse('19 May 2022'),
  },
  {
    id: '23',
    description: 'approval request',
    customerId: '12',
    customerName: 'anthony',
    caseType: 'approval',
    date: Date.parse('14 Apr 2022'),
  },
  {
    id: '24',
    description: 'transaction request',
    customerId: '12',
    customerName: 'anthony',
    caseType: 'product',
    date: Date.parse('24 Jul 2022'),
  },
];
