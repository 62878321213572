import { utils, writeFileXLSX } from 'xlsx';

export const handleExportToExcel = (tableData: any[]) => {
  tableData.forEach((element) => delete element.key);

  const ws = utils.json_to_sheet(tableData);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, 'Sheet');
  writeFileXLSX(wb, 'TableData.xlsx');
};
