import LoginForm from '../../components/login-form/LoginForm';
import './LoginPage.css';

const LoginPage = () => {
  return (
    <div id='login-form'>
      <LoginForm />
    </div>
  );
};

export default LoginPage;
