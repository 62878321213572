import { Modal } from 'antd';
import { CustomerModalContent } from './CustomerModalContent';

const CustomerModal = (modalType: string) => {
  let modal = Modal;

  if (modalType === 'view') {
    modal.info({
      title: 'Customer Management',
      width: '70%',
      content: CustomerModalContent(false),
      maskClosable: true,
      centered: true,
      icon: null,
    });
  }
  if (modalType === 'edit') {
    modal.info({
      title: 'Customer Management',
      width: '70%',
      content: CustomerModalContent(true),
      maskClosable: true,
      centered: true,
      icon: null,
    });
  }
};

export default CustomerModal;
