import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../../../redux/app/hooks';
import { PATH_GENERAL } from '../../../routes/paths';
import { ApprovalsGuardProps } from '../@types';

const ApprovalsGuard: React.FC<ApprovalsGuardProps> = ({ children }) => {
  const { authUser } = useAppSelector((state) => state.auth);

  return authUser?.category === 'officer' ? (
    <Navigate to={PATH_GENERAL.root} />
  ) : (
    <>{children}</>
  );
};

export default ApprovalsGuard;
