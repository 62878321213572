import { CSSProperties } from 'react';

export const descriptionsDiv: CSSProperties | undefined = {
  backgroundColor: '#fafafa',
  width: '55.5%',
};

export const descriptions = {
  marginRight: '2.5rem',
};

export const descriptionItemStyles: CSSProperties | undefined = {
  backgroundColor: 'rgb(240, 242, 245)',
  borderBottomStyle: 'solid',
  borderWidth: '1.5px',
  borderColor: '#fafafa',
};

export const profitabilityChartDiv: CSSProperties | undefined = {
  backgroundColor: '#fafafa',
  width: '65%',
};

export const productAnalysisTableInPortal: CSSProperties | undefined = {
  marginBottom: '1.5rem',
  backgroundColor: 'rgb(240, 242, 245)',
};

export const productAnalysisTableInModal: CSSProperties | undefined = {
  overflowY: 'scroll',
  height: '60vh',
  marginBottom: '1.5rem',
};
