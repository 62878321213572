import { Button, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ProductListItem from '../product-list/ProductListItem';
import { AddFieldButtonPropTypes } from '../../@types';

const AddFieldButton: React.FC<AddFieldButtonPropTypes> = ({ isEdit }) => {
  return (
    <Form autoComplete='off'>
      <Form.List name='users'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <ProductListItem key={key} isEdit={isEdit} />
            ))}
            <Form.Item>
              <Button
                type='dashed'
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
                disabled={isEdit ? false : true}
              >
                Add field
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default AddFieldButton;
