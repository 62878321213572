import { useAppSelector } from '../../../../redux/app/hooks';
import { Card, Col, Row } from 'antd';
import Spinner from '../../../../components/spinner';

const AmountCards = () => {
  const { selectedScenario, isLoading } = useAppSelector(
    (state) => state.scenarios
  );
  const {
    exchange: { exchangeRate, moneySign },
  } = useAppSelector((state) => state.currency);

  const cardStyles = {
    fontSize: 'x-large',
    fontWeight: 'bold',
  };

  if (isLoading) return <Spinner />;

  return (
    <Row style={{ marginBottom: '4rem' }} gutter={36}>
      <Col span={6}>
        <Card
          headStyle={{
            backgroundColor: 'blue',
            color: 'white',
          }}
          title='Actual Profitability'
          bordered={false}
          style={cardStyles}
        >
          {`${moneySign} ${
            selectedScenario[0].totalProfitability * exchangeRate
          }`}
        </Card>
      </Col>
      <Col span={6}>
        <Card
          headStyle={{ backgroundColor: 'blue', color: 'white' }}
          title='Future Profitability (1 year)'
          bordered={false}
          style={cardStyles}
        >
          {`${moneySign} ${
            parseFloat(selectedScenario.newProfitability.split(' ')[1]) *
            exchangeRate
          }`}
        </Card>
      </Col>
      <Col span={6}>
        <Card
          headStyle={{ backgroundColor: 'blue', color: 'white' }}
          title='Actual Amount Due'
          bordered={false}
          style={cardStyles}
        >
          {`${moneySign} ${(
            selectedScenario[0].totalProfitability *
            exchangeRate *
            0.1
          ).toFixed(1)}`}
        </Card>
      </Col>
      <Col span={6}>
        <Card
          headStyle={{ backgroundColor: 'blue', color: 'white' }}
          title='Future Amount Due (1 year)'
          bordered={false}
          style={cardStyles}
        >
          {`${moneySign} ${(
            selectedScenario[0].totalProfitability *
            exchangeRate *
            0.02
          ).toFixed(1)}`}
        </Card>
      </Col>
    </Row>
  );
};

export default AmountCards;
