import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../redux/app/hooks';
import { useFilterTransactions } from '../../hooks';
import { LineChart, Line, Tooltip, Label } from 'recharts';
import { Card, Typography, Space } from 'antd';
import { TRANSACTIONS } from '../../../../_mock/data';
import { TransactionTypes } from '../../../../_mock/@types';
import { totalRepaymentCardStyles, sharedStyles } from './styles';

const { Title } = Typography;

const fulfiledTransactions = TRANSACTIONS.filter(
  (transaction: TransactionTypes) =>
    transaction.type === 'repayment' && transaction.paid === true
);

const TotalRepaymentCard = () => {
  // eslint-disable-next-line
  const [transactions, setTransactions] =
    useState<TransactionTypes[]>(fulfiledTransactions);
  const [selectedTransactions, setSelectedTransactions] = useState<
    TransactionTypes[] | null
  >(null);
  const { date } = useAppSelector((state) => state.dates);
  const { recentTransactions } = useAppSelector((state) => state.transactions);
  const { selectedUser } = useAppSelector((state) => state.hierarchyTree);
  useFilterTransactions(date);

  useEffect(() => {
    if (!date) {
      setSelectedTransactions(null);
    }
  }, [date]);

  useEffect(() => {
    if (recentTransactions && !selectedUser) {
      setSelectedTransactions(recentTransactions);
    }
  }, [recentTransactions, selectedUser]);

  useEffect(() => {
    //@ts-ignore
    if (recentTransactions && selectedUser?.officerId) {
      const transactions = recentTransactions.filter(
        (transaction: TransactionTypes) =>
          transaction.customerId === selectedUser.id
      );

      setSelectedTransactions(transactions);
    }
  }, [recentTransactions, selectedUser]);

  return (
    <Card
      hoverable
      //@ts-ignore
      style={totalRepaymentCardStyles.cardStyles}
      cover={
        <>
          <Space direction='vertical' style={sharedStyles.spaceStyles}>
            <Title style={sharedStyles.titleStyles} level={4}>
              Total Repayments
            </Title>
            <Title style={sharedStyles.titleStyles} level={4}>
              {selectedTransactions
                ? selectedTransactions.length
                : transactions.length}
            </Title>
          </Space>
          <LineChart
            width={240}
            height={300}
            data={selectedTransactions ? selectedTransactions : transactions}
            margin={sharedStyles.chartStyles}
          >
            <Label value='Total Bank Revenue' offset={0} position='insideTop' />
            <Tooltip />
            <Line
              dot={false}
              type='monotone'
              dataKey='amount'
              stroke='black'
              strokeWidth={2}
            />
          </LineChart>
        </>
      }
    />
  );
};

export default TotalRepaymentCard;
