import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../redux/app/hooks';
import { logoutUser } from '../../modules/auth/redux/authSlice';
import { resetAllAction } from '../../redux/actions';
import { Layout, Menu, Button } from 'antd';
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { PATH_GENERAL } from '../../routes/paths';
import { MenuItem } from '../../@types';

const { Sider } = Layout;

const getItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
};

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(logoutUser());
    dispatch(resetAllAction());
  };

  const items: MenuItem[] = [
    getItem(
      <Link to={PATH_GENERAL.root}>Dashboard</Link>,
      '1',
      <PieChartOutlined />
    ),
    getItem(
      <Link to={PATH_GENERAL.customerManagement}>Customer Management</Link>,
      '2',
      <DesktopOutlined />
    ),
    getItem(
      <Link to={PATH_GENERAL.scenarios}>Scenarios</Link>,
      '3',
      <UserOutlined />
    ),
    getItem(
      <Link to={PATH_GENERAL.approvals}>Approvals</Link>,
      '4',
      <TeamOutlined />
    ),
    getItem(
      <Link to={PATH_GENERAL.events}>Events</Link>,
      '5',
      <FileOutlined />
    ),
    {
      type: 'divider',
    },
    getItem(
      <Button
        style={{
          backgroundColor: '#001529',
          color: 'hsla(0,0%,100%,.65)',
          border: '1px solid #001529',
          paddingLeft: '0px',
        }}
        icon={<LogoutOutlined />}
        onClick={() => handleLogout()}
      >
        Logout
      </Button>,
      '6'
    ),
  ];

  const findMenuItemKey = (pathName: string) => {
    let selectedKey = 3;

    if (pathName === '/dashboard') {
      selectedKey = 1;
    }
    if (pathName === '/customer-management') {
      selectedKey = 2;
    }
    if (pathName === '/scenarios') {
      selectedKey = 3;
    }
    if (pathName === '/approvals') {
      selectedKey = 4;
    }
    if (pathName === '/events') {
      selectedKey = 5;
    }
    return selectedKey;
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div className='logo' />
      <Menu
        theme='dark'
        defaultSelectedKeys={['1']}
        selectedKeys={[`${findMenuItemKey(pathname)}`]}
        mode='inline'
        items={items}
      />
    </Sider>
  );
};

export default React.memo(Sidebar);
