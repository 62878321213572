import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { profitabilityChartDiv } from './styles';
import { PortalPropTypes } from '../../@types';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const profitability = [
  40000, 45000, 50000, 55000, 60000, 65000, 70000, 75000, 80000, 85000, 90000,
  95000,
];
const amountDue = [
  35000, 32500, 30000, 27500, 25000, 22500, 20000, 17500, 15000, 12500, 10000,
  7500,
];

const data = [...Array(12)].map((_, index) => ({
  name: months[index],
  Profitability: profitability[index],
  AmountDue: amountDue[index],
}));

const ProfitabilityChart: React.FC<PortalPropTypes> = ({ isPortal }) => {
  return (
    <div
      id='profitability-chart'
      style={isPortal ? profitabilityChartDiv : undefined}
    >
      <BarChart
        width={1000}
        height={350}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey='Profitability' fill='#0000FF' />
        <Bar dataKey='AmountDue' fill='#ff0000' />
      </BarChart>
    </div>
  );
};

export default ProfitabilityChart;
