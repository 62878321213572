import { SCENARIOS } from './scenarios';
import { PRODUCT_OFFERS } from './product-offers';
import moment from 'moment';
import { ApprovalTypes } from '../@types';

export const APPROVALS: ApprovalTypes[] = [
  {
    id: '1',
    customerId: '1',
    customerName: 'max',
    productId: '1',
    productName: PRODUCT_OFFERS[0].name,
    requestReason: 'something',
    expirationDate: moment(Date.parse('12 Mar 2022')).format('DD/MM/YYYY'),
    status: 'pending',
    userId: '4',
    userName: 'george',
    approvalLevel: 'sector',
    scenarioId: SCENARIOS[0].id,
  },
  {
    id: '2',
    customerId: '1',
    customerName: 'max',
    productId: '2',
    productName: PRODUCT_OFFERS[2].name,
    requestReason: 'something',
    expirationDate: moment(Date.parse('16 Mar 2022')).format('DD/MM/YYYY'),
    status: 'approved',
    userId: '2',
    userName: 'mairy',
    approvalLevel: 'unit',
    scenarioId: SCENARIOS[1].id,
  },
  {
    id: '3',
    customerId: '2',
    customerName: 'josephine',
    productId: '1',
    productName: PRODUCT_OFFERS[1].name,
    requestReason: 'something',
    expirationDate: moment(Date.parse('26 Mar 2022')).format('DD-MM-YYYY'),
    status: 'pending',
    userId: '4',
    userName: 'george',
    approvalLevel: 'sector',
    scenarioId: SCENARIOS[2].id,
  },
  {
    id: '4',
    customerId: '2',
    customerName: 'josephine',
    productId: '2',
    productName: PRODUCT_OFFERS[3].name,
    requestReason: 'something',
    expirationDate: moment(Date.parse('12 Apr 2022')).format('DD-MM-YYYY'),
    status: 'approved',
    userId: '2',
    userName: 'mairy',
    approvalLevel: 'unit',
    scenarioId: SCENARIOS[3].id,
  },
  {
    id: '5',
    customerId: '3',
    customerName: 'dereck',
    productId: '1',
    productName: PRODUCT_OFFERS[4].name,
    requestReason: 'something',
    expirationDate: moment(Date.parse('16 Apr 2022')).format('DD-MM-YYYY'),
    status: 'pending',
    userId: '4',
    userName: 'george',
    approvalLevel: 'sector',
    scenarioId: SCENARIOS[4].id,
  },
  {
    id: '6',
    customerId: '3',
    customerName: 'dereck',
    productId: '2',
    productName: PRODUCT_OFFERS[5].name,
    requestReason: 'something',
    expirationDate: moment(Date.parse('26 Apr 2022')).format('DD-MM-YYYY'),
    status: 'approved',
    userId: '2',
    userName: 'mairy',
    approvalLevel: 'unit',
    scenarioId: SCENARIOS[5].id,
  },
  {
    id: '7',
    customerId: '4',
    customerName: 'devon',
    productId: '1',
    productName: PRODUCT_OFFERS[1].name,
    requestReason: 'something',
    expirationDate: moment(Date.parse('12 Jun 2022')).format('DD-MM-YYYY'),
    status: 'pending',
    userId: '5',
    userName: 'manuel',
    approvalLevel: 'sector',
    scenarioId: SCENARIOS[0].id,
  },
  {
    id: '8',
    customerId: '4',
    customerName: 'devon',
    productId: '2',
    productName: PRODUCT_OFFERS[2].name,
    requestReason: 'something',
    expirationDate: moment(Date.parse('16 Jun 2022')).format('DD-MM-YYYY'),
    status: 'approved',
    userId: '2',
    userName: 'mairy',
    approvalLevel: 'unit',
    scenarioId: SCENARIOS[1].id,
  },
  {
    id: '9',
    customerId: '5',
    customerName: 'simon',
    productId: '1',
    productName: PRODUCT_OFFERS[0].name,
    requestReason: 'something',
    expirationDate: moment(Date.parse('26 Jun 2022')).format('DD-MM-YYYY'),
    status: 'pending',
    userId: '5',
    userName: 'manuel',
    approvalLevel: 'sector',
    scenarioId: SCENARIOS[2].id,
  },
  {
    id: '10',
    customerId: '5',
    customerName: 'simon',
    productId: '2',
    productName: PRODUCT_OFFERS[5].name,
    requestReason: 'something',
    expirationDate: moment(Date.parse('12 Feb 2022')).format('DD-MM-YYYY'),
    status: 'approved',
    userId: '2',
    userName: 'mairy',
    approvalLevel: 'unit',
    scenarioId: SCENARIOS[3].id,
  },
  {
    id: '11',
    customerId: '6',
    customerName: 'dolly',
    productId: '1',
    productName: PRODUCT_OFFERS[4].name,
    requestReason: 'something',
    expirationDate: moment(Date.parse('16 Feb 2022')).format('DD-MM-YYYY'),
    status: 'pending',
    userId: '5',
    userName: 'manuel',
    approvalLevel: 'sector',
    scenarioId: SCENARIOS[4].id,
  },
  {
    id: '12',
    customerId: '6',
    customerName: 'dolly',
    productId: '2',
    productName: PRODUCT_OFFERS[0].name,
    requestReason: 'something',
    expirationDate: moment(Date.parse('26 Feb 2022')).format('DD-MM-YYYY'),
    status: 'approved',
    userId: '2',
    userName: 'mairy',
    approvalLevel: 'unit',
    scenarioId: SCENARIOS[5].id,
  },
  {
    id: '13',
    customerId: '7',
    customerName: 'brad',
    productId: '1',
    productName: PRODUCT_OFFERS[1].name,
    requestReason: 'something',
    expirationDate: moment(Date.parse('12 Jul 2022')).format('DD-MM-YYYY'),
    status: 'pending',
    userId: '6',
    userName: 'paul',
    approvalLevel: 'sector',
    scenarioId: SCENARIOS[0].id,
  },
  {
    id: '14',
    customerId: '7',
    customerName: 'brad',
    productId: '2',
    productName: PRODUCT_OFFERS[3].name,
    requestReason: 'something',
    expirationDate: moment(Date.parse('16 Jul 2022')).format('DD-MM-YYYY'),
    status: 'approved',
    userId: '3',
    userName: 'nicole',
    approvalLevel: 'unit',
    scenarioId: SCENARIOS[1].id,
  },
  {
    id: '15',
    customerId: '8',
    customerName: 'keith',
    productId: '1',
    productName: PRODUCT_OFFERS[4].name,
    requestReason: 'something',
    expirationDate: moment(Date.parse('26 Jul 2022')).format('DD-MM-YYYY'),
    status: 'pending',
    userId: '6',
    userName: 'paul',
    approvalLevel: 'sector',
    scenarioId: SCENARIOS[2].id,
  },
  {
    id: '16',
    customerId: '8',
    customerName: 'keith',
    productId: '2',
    productName: PRODUCT_OFFERS[2].name,
    requestReason: 'something',
    expirationDate: moment(Date.parse('12 Jul 2022')).format('DD-MM-YYYY'),
    status: 'approved',
    userId: '3',
    userName: 'nicole',
    approvalLevel: 'unit',
    scenarioId: SCENARIOS[3].id,
  },
  {
    id: '17',
    customerId: '9',
    customerName: 'helen',
    productId: '1',
    productName: PRODUCT_OFFERS[5].name,
    requestReason: 'something',
    expirationDate: moment(Date.parse('16 Jul 2022')).format('DD-MM-YYYY'),
    status: 'pending',
    userId: '6',
    userName: 'paul',
    approvalLevel: 'sector',
    scenarioId: SCENARIOS[4].id,
  },
  {
    id: '18',
    customerId: '9',
    customerName: 'helen',
    productId: '2',
    productName: PRODUCT_OFFERS[1].name,
    requestReason: 'something',
    expirationDate: moment(Date.parse('26 Jul 2022')).format('DD-MM-YYYY'),
    status: 'approved',
    userId: '3',
    userName: 'nicole',
    approvalLevel: 'unit',
    scenarioId: SCENARIOS[5].id,
  },
  {
    id: '19',
    customerId: '10',
    customerName: 'anna',
    productId: '1',
    productName: PRODUCT_OFFERS[0].name,
    requestReason: 'something',
    expirationDate: moment(Date.parse('12 Jun 2022')).format('DD-MM-YYYY'),
    status: 'pending',
    userId: '7',
    userName: 'peter',
    approvalLevel: 'sector',
    scenarioId: SCENARIOS[0].id,
  },
  {
    id: '20',
    customerId: '10',
    customerName: 'anna',
    productId: '2',
    productName: PRODUCT_OFFERS[4].name,
    requestReason: 'something',
    expirationDate: moment(Date.parse('16 Jun 2022')).format('DD-MM-YYYY'),
    status: 'approved',
    userId: '3',
    userName: 'nicole',
    approvalLevel: 'unit',
    scenarioId: SCENARIOS[1].id,
  },
  {
    id: '21',
    customerId: '11',
    customerName: 'logan',
    productId: '1',
    productName: PRODUCT_OFFERS[0].name,
    requestReason: 'something',
    expirationDate: moment(Date.parse('26 Jun 2022')).format('DD-MM-YYYY'),
    status: 'pending',
    userId: '7',
    userName: 'peter',
    approvalLevel: 'sector',
    scenarioId: SCENARIOS[2].id,
  },
  {
    id: '22',
    customerId: '11',
    customerName: 'logan',
    productId: '2',
    productName: PRODUCT_OFFERS[5].name,
    requestReason: 'something',
    expirationDate: moment(Date.parse('12 Jan 2022')).format('DD-MM-YYYY'),
    status: 'approved',
    userId: '3',
    userName: 'nicole',
    approvalLevel: 'unit',
    scenarioId: SCENARIOS[3].id,
  },
  {
    id: '23',
    customerId: '12',
    customerName: 'anthony',
    productId: '1',
    productName: PRODUCT_OFFERS[2].name,
    requestReason: 'something',
    expirationDate: moment(Date.parse('16 Jan 2022')).format('DD-MM-YYYY'),
    status: 'pending',
    userId: '7',
    userName: 'peter',
    approvalLevel: 'sector',
    scenarioId: SCENARIOS[4].id,
  },
  {
    id: '24',
    customerId: '12',
    customerName: 'anthony',
    productId: '2',
    productName: PRODUCT_OFFERS[1].name,
    requestReason: 'something',
    expirationDate: moment(Date.parse('26 Jan 2022')).format('DD-MM-YYYY'),
    status: 'approved',
    userId: '3',
    userName: 'nicole',
    approvalLevel: 'unit',
    scenarioId: SCENARIOS[5].id,
  },
];
