import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../redux/app/hooks';
import { useFilterTransactions } from '../../hooks';
import { handleElementWidth } from '../../../../utils';
import { Space, Table, Tag, Typography, Button } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { ExpandOutlined, ShrinkOutlined } from '@ant-design/icons';
import { TransactionTypes, CustomerTypes } from '../../../../_mock/@types';
import { DataType, RecentTransactionTypes } from '../../@types';
import { CUSTOMERS } from '../../../../_mock/data';
import moment from 'moment';
import DownloadButton from '../../../../components/download-button';

const { Title } = Typography;

const columns: ColumnsType<RecentTransactionTypes> = [
  {
    title: 'Customer',
    dataIndex: 'customerName',
    key: 'customer',
    filters: CUSTOMERS.map((customer: CustomerTypes) => ({
      text: customer.name,
      value: customer.name,
    })),
    onFilter: (value: any, record) => record.customerName.includes(value),
    sorter: (a, b) => a.customerName.length - b.customerName.length,
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    sorter: {
      compare: (a, b) => {
        const A = parseInt(a.amount.split(' ')[1]);
        const B = parseInt(b.amount.split(' ')[1]);
        return A - B;
      },
    },
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    sorter: {
      //@ts-ignore
      compare: (a, b) => Date.parse(a.date) - Date.parse(b.date),
    },
  },
  {
    title: 'Type',
    key: 'type',
    dataIndex: 'type',
    render: (_, { type }) => (
      <Tag color={'geekblue'} key={type}>
        {type.toUpperCase()}
      </Tag>
    ),
    filters: [
      { text: 'installment', value: 'installment' },
      { text: 'repayment', value: 'repayment' },
    ],
    onFilter: (value: any, record) => record.type.includes(value),
    sorter: (a, b) => a.type.length - b.type.length,
  },
];

const TransactionsTable: React.FC = () => {
  const [transactions, setTransactions] = useState<
    RecentTransactionTypes[] | null
  >(null);
  const { date } = useAppSelector((state) => state.dates);
  const { selectedUser } = useAppSelector((state) => state.hierarchyTree);
  const { recentTransactions } = useAppSelector((state) => state.transactions);
  const {
    exchange: { exchangeRate, moneySign },
  } = useAppSelector((state) => state.currency);
  useFilterTransactions(date);

  useEffect(() => {
    if (!date) {
      setTransactions(null);
    }
  }, [date]);

  useEffect(() => {
    if (recentTransactions && !selectedUser) {
      const transactions = recentTransactions.map(
        (transaction: TransactionTypes) => ({
          ...transaction,
          amount: `${moneySign} ${Math.round(
            transaction.amount * exchangeRate
          )}`,
          key: transaction.id,
          date: moment(transaction.date).format('DD/MM/YYYY'),
        })
      );

      setTransactions(transactions);
    }
  }, [recentTransactions, exchangeRate, moneySign, selectedUser]);

  useEffect(() => {
    //@ts-ignore
    if (recentTransactions && selectedUser?.officerId) {
      const transactions = recentTransactions
        .filter(
          (transaction: TransactionTypes) =>
            transaction.customerId === selectedUser.id
        )
        .map((transaction: TransactionTypes) => ({
          ...transaction,
          amount: `${moneySign} ${Math.round(
            transaction.amount * exchangeRate
          )}`,
          key: transaction.id,
          date: moment(transaction.date).format('DD/MM/YYYY'),
        }));

      setTransactions(transactions);
    }
  }, [recentTransactions, exchangeRate, moneySign, selectedUser]);

  const onChange: TableProps<DataType>['onChange'] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  return (
    <div id='recent-transactions-div' style={{ marginTop: '2rem' }}>
      <Space
        style={{ display: 'flex', justifyContent: 'space-between' }}
        direction='horizontal'
        align='center'
      >
        <Title level={3}>Recent Transactions</Title>
        <Space>
          <DownloadButton
            excelData={transactions}
            elementId={'recent-transactions-table'}
          />
          <Button
            shape='round'
            icon={<ShrinkOutlined />}
            onClick={() =>
              handleElementWidth('recent-transactions-div', '100%')
            }
          />
          <Button
            shape='round'
            icon={<ExpandOutlined />}
            onClick={() =>
              handleElementWidth('recent-transactions-div', '115%')
            }
          />
        </Space>
      </Space>

      {transactions ? (
        <Table
          id='recent-transactions-table'
          columns={columns}
          dataSource={transactions}
          onChange={() => onChange}
        />
      ) : (
        <p>
          No transactions selected. Plase pick a date to see the recent
          transactions
        </p>
      )}
    </div>
  );
};

export default TransactionsTable;
