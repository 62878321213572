import { createSlice } from '@reduxjs/toolkit';
import { resetAllAction } from '../../../../redux/actions';
import { DateInitialStateTypes } from '../../@types';

const initialState: DateInitialStateTypes = {
  date: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
};

export const dateSlice = createSlice({
  name: 'date',
  initialState,
  reducers: {
    reset: () => initialState,
    setDates: (state, action) => {
      state.isSuccess = true;
      state.date = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllAction, () => initialState);
  },
});

export const { reset, setDates } = dateSlice.actions;
export default dateSlice.reducer;
