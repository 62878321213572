import { PRODUCTS } from './products';
import { ProductOfferTypes } from '../@types';

export const PRODUCT_OFFERS: ProductOfferTypes[] = [
  {
    id: '1',
    initialProductId: PRODUCTS[0].id,
    name: PRODUCTS[0].name,
    description: 'description of product 1',
    type: 'loan',
    serviceDiscountPercentage: null,
    loanAmount: 3278,
    loanInterestRate: 0.4,
    isActual: true,
    scenarioId: null,
    approvalStatus: 'pending',
  },
  {
    id: '2',
    initialProductId: PRODUCTS[1].id,
    name: PRODUCTS[1].name,
    description: 'description of product 2',
    type: 'service',
    serviceDiscountPercentage: 0.1,
    loanAmount: null,
    loanInterestRate: null,
    isActual: false,
    scenarioId: '1',
    approvalStatus: 'pending',
  },
  {
    id: '3',
    initialProductId: PRODUCTS[2].id,
    name: PRODUCTS[2].name,
    description: 'description of product 3',
    type: 'loan',
    serviceDiscountPercentage: null,
    loanAmount: 5678,
    loanInterestRate: 0.7,
    isActual: true,
    scenarioId: null,
    approvalStatus: 'approved',
  },
  {
    id: '4',
    initialProductId: PRODUCTS[3].id,
    name: PRODUCTS[3].name,
    description: 'description of product 4',
    type: 'service',
    serviceDiscountPercentage: 0.3,
    loanAmount: null,
    loanInterestRate: null,
    isActual: false,
    scenarioId: '2',
    approvalStatus: 'approved',
  },
  {
    id: '5',
    initialProductId: PRODUCTS[4].id,
    name: PRODUCTS[4].name,
    description: 'description of product 5',
    type: 'loan',
    serviceDiscountPercentage: null,
    loanAmount: 6547,
    loanInterestRate: 0.4,
    isActual: true,
    scenarioId: null,
    approvalStatus: 'rejected',
  },
  {
    id: '6',
    initialProductId: PRODUCTS[5].id,
    name: PRODUCTS[5].name,
    description: 'description of product 6',
    type: 'service',
    serviceDiscountPercentage: 0.4,
    loanAmount: null,
    loanInterestRate: null,
    isActual: false,
    scenarioId: '3',
    approvalStatus: 'rejected',
  },
];
