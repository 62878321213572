import { useAppSelector } from '../../../../redux/app/hooks';
import { useNavigate } from 'react-router-dom';
import type { BadgeProps } from 'antd';
// eslint-disable-next-line
import { Badge, Calendar, Tooltip, Popconfirm, Alert } from 'antd';
import type { Moment } from 'moment';
// eslint-disable-next-line
import { getListData, getMonthData, eventList } from '../../utils';
import { PATH_GENERAL } from '../../../../routes/paths';
import { EventCalendarPropTypes } from '../../@types';
import './EventCalendar.css';

const EventCalendar: React.FC<EventCalendarPropTypes> = ({
  handleEventsTableData,
}) => {
  const { selectedUser } = useAppSelector((state) => state.hierarchyTree);
  const navigate = useNavigate();

  const linkTo = PATH_GENERAL.approvals;

  const monthCellRender = (value: Moment) => {
    const num = getMonthData(value);
    return num ? (
      <div className='notes-month'>
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  };

  const dateCellRender = (value: Moment) => {
    let listData = getListData(value);
    //@ts-ignore
    if (selectedUser?.officerId) {
      listData = getListData(value).filter(
        (data: any) => data.customerName === selectedUser.name
      );
    }
    // handleEventsTableData(listData, 'calendar-div');
    return (
      <ul className='events'>
        {listData.map((item) => (
          <li key={item.content}>
            <Popconfirm
              title={item.content}
              // onConfirm={confirm}
              // onCancel={cancel}
              okText='View'
              onConfirm={() => navigate(linkTo)}
              cancelText='Cancel'
            >
              <Tooltip title={item.content} placement='left'>
                <Badge
                  status={item.type as BadgeProps['status']}
                  text={item.content}
                />
                <span>{item.content}</span>
              </Tooltip>
            </Popconfirm>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      {/* {eventList?.map((item: any) => (
        <li
          key={item.content}
          style={{ listStyle: 'none', marginBottom: '4px' }}
          onClick={() => console.log(item.content)}
        >
          {item.isDue && item.type === 'warning' && (
            <Alert
              message='Approval request expires soon'
              description={item.content}
              type='warning'
              banner={true}
              closable
            />
          )}
          {item.isDue && item.type === 'error' && (
            <Alert
              message='Payment is due'
              description={item.content}
              type='error'
              banner={true}
              closable
            />
          )}
        </li>
      ))} */}
      <div id='calendar-div'>
        <Calendar
          dateCellRender={dateCellRender}
          monthCellRender={monthCellRender}
          style={{ padding: '1rem' }}
        />
      </div>
    </>
  );
};

export default EventCalendar;
