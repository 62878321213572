import * as _ from 'lodash';
import { APPROVALS } from '../../../../_mock/data';
import { ApprovalTypes } from '../../../../_mock/@types';

const selectApprovals = (userID: string) => {
  const selectedApprovals: ApprovalTypes[] = _.filter(
    APPROVALS,
    (approval) => approval.userId === userID
  );

  return selectedApprovals;
};

const approvalService = {
  selectApprovals,
};

export default approvalService;
