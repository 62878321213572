import { ScenariosTypes, CustomerTypes } from '../../../_mock/@types';

export const mergeById = (
  scenarios: ScenariosTypes[],
  customers: CustomerTypes[]
) =>
  scenarios.map((scenario) => ({
    ...customers.filter(
      (customer) => customer.id === scenario.customerId && customer
    ),
    ...scenario,
    key: scenario.id,
  }));
