import { useState } from 'react';
import { useAppSelector } from '../../../../redux/app/hooks';
import { useUserIsIdle } from '../../../auth/hooks';
import { useSelectDates } from '../../hooks';
import moment from 'moment';
import { Typography, DatePicker, Space, Button } from 'antd';
import CardList from '../../components/card-list/CardList';
import TransactionsTable from '../../components/transactions-table/TransactionsTable';

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

let today: string = new Date().toDateString();

const DashboardPage: React.FC = () => {
  const [date, setDate] = useState<any>();
  const { date: reduxDate } = useAppSelector((state) => state.dates);
  // useUserIsIdle();
  useSelectDates(date);

  return (
    <div style={{ width: '120%' }}>
      <Title level={2}>Dashboard</Title>
      <Space
        style={{ display: 'flex', justifyContent: 'space-between' }}
        direction='horizontal'
        align='center'
      >
        <Text type='secondary'>{today}</Text>
        <Space>
          {reduxDate && (
            <Button shape='round' onClick={() => setDate(null)}>
              Clear Dates
            </Button>
          )}
          <RangePicker
            value={
              reduxDate && [
                moment(reduxDate.startDate),
                moment(reduxDate.endDate),
              ]
            }
            onChange={(date) => setDate(date)}
          />
        </Space>
      </Space>
      <CardList />
      <TransactionsTable />
    </div>
  );
};

export default DashboardPage;
