import { Provider } from 'react-redux';
import { store } from '../../../../redux/app/store';
import ReviewScenarioModalContent from './ReviewScenarioModalContent';

const ReviewScenarioModalProvider = () => {
  return (
    <Provider store={store}>
      <ReviewScenarioModalContent />
    </Provider>
  );
};

export default ReviewScenarioModalProvider;
