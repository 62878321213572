import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from '../../modules/auth/redux';
import dateReducer from '../../modules/dashboard/redux/date';
import transactionReducer from '../../modules/dashboard/redux/transactions';
import approvalReducer from '../../modules/approvals/redux/approvals';
import hierarchyTreeReducer from '../hierarchy-tree';
import managementReducer from '../../modules/customer-management/redux/management';
import scenarioReducer from '../../modules/scenarios/redux/scenarios';
import currencyChangeReducer from '../currency-change';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    currency: currencyChangeReducer,
    hierarchyTree: hierarchyTreeReducer,
    dates: dateReducer,
    transactions: transactionReducer,
    approvals: approvalReducer,
    management: managementReducer,
    scenarios: scenarioReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
