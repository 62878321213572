const path = (root: string, sublink: string) => `${root}${sublink}`;

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
  login: path(ROOTS_AUTH, '/login'),
};

export const PATH_GENERAL = {
  root: ROOTS_DASHBOARD,
  customerManagement: '/customer-management',
  scenarios: '/scenarios',
  selectedScenario: (scenarioId: string) =>
    path(PATH_GENERAL.scenarios, `/scenario-${scenarioId}`),
  approvals: '/approvals',
  events: '/events',
};
