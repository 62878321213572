import { Space, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { ReviewScenarioModal } from '../review-scenario-modal';
import ApprovalsModal from '../approvals-modal';
import { ActionButtonsPropTypes } from '../../@types';

import { handleScenarioReportExportToPdf } from '../../../../utils';

const ActionButtons: React.FC<ActionButtonsPropTypes> = ({
  isDisabled,
  isVisible,
  handleCloseModal,
  setApprovalModal,
  ScenarioReportData,
}) => {
  return (
    <Space
      style={{
        display: 'flex',
        marginBlock: '3rem',
        justifyContent: 'flex-end',
      }}
    >
      <Button
        type='primary'
        shape='round'
        icon={<DownloadOutlined />}
        onClick={() =>
          handleScenarioReportExportToPdf(
            [
              'customer-info-div',
              'profitability-chart',
              'product-analysis-table',
            ],
            ScenarioReportData
          )
        }
      />
      <Button
        type='primary'
        danger
        disabled={isDisabled ? true : false}
        onClick={() => setApprovalModal()}
      >
        Approvals
        <ApprovalsModal
          isVisible={isVisible}
          handleCloseModal={handleCloseModal}
        />
      </Button>
      <Button type='primary' onClick={() => ReviewScenarioModal()}>
        Submit
      </Button>
    </Space>
  );
};

export default ActionButtons;
