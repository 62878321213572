import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { logoutUser } from '../../modules/auth/redux/authSlice';
import { resetAllAction } from '../../redux/actions';
import { changeCurrency } from '../../redux/currency-change/currencyChangeSlice';
import { Button, Layout, Menu, Dropdown, Tooltip } from 'antd';
import {
  UserOutlined,
  DollarCircleOutlined,
  EuroCircleOutlined,
} from '@ant-design/icons';

const { Header: HeaderNav } = Layout;

const Header = () => {
  const {
    exchange: { currency },
  } = useAppSelector((state) => state.currency);
  const { authUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(logoutUser());
    dispatch(resetAllAction());
  };

  const menu = (
    <Menu
      onClick={handleClick}
      items={[
        {
          label: authUser?.name,
          disabled: true,
          key: '1',
        },
        {
          type: 'divider',
        },
        {
          label: 'Logout',
          key: '2',
        },
      ]}
    />
  );

  return (
    <HeaderNav
      id='header'
      className='site-layout-background'
      style={{ padding: 0, backgroundColor: '#f0f2f5' }}
    >
      {currency === 'EURO' ? (
        <Tooltip color='blue' title='Change to USD'>
          <Button
            id='money-sign-button'
            shape='circle'
            style={{ backgroundColor: '#f0f2f5' }}
            icon={<DollarCircleOutlined />}
            onClick={() => dispatch(changeCurrency('USD'))}
          />
        </Tooltip>
      ) : (
        <Tooltip color='blue' title='Change to Euro'>
          <Button
            id='money-sign-button'
            shape='circle'
            style={{ backgroundColor: '#f0f2f5' }}
            icon={<EuroCircleOutlined />}
            onClick={() => dispatch(changeCurrency('EURO'))}
          />
        </Tooltip>
      )}
      <Dropdown overlay={menu} trigger={['click']}>
        <Button
          id='user-button'
          shape='circle'
          style={{ backgroundColor: '#f0f2f5' }}
          icon={<UserOutlined />}
        />
      </Dropdown>
    </HeaderNav>
  );
};

export default Header;
