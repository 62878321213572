import { Provider } from 'react-redux';
import { store } from '../../../../redux/app/store';
import CustomerInfo from '../customer-info';
import ProductList from '../product-list';
import AddFieldButton from '../add-field-button';

export const CustomerModalContent = (isEdit: boolean) => {
  return (
    <div style={{ height: '75vh', overflow: 'auto' }}>
      <Provider store={store}>
        <CustomerInfo />
        <ProductList isEdit={isEdit} />
        <AddFieldButton isEdit={isEdit} />
      </Provider>
    </div>
  );
};
