import { Menu, Dropdown } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { handleExportToExcel, handleExportToPdf } from '../../utils';
import { DownloadButtonPropTypes } from '../../@types';

const DownloadButton: React.FC<DownloadButtonPropTypes> = ({
  excelData,
  elementId,
}) => {
  const menu = (
    <Menu
      onClick={({ item, key }) => {
        if (excelData === null) {
          return window.alert('Please select some transactions');
        }
        if (key === '0') {
          return handleExportToExcel(excelData!);
        }
        if (key === '1') {
          return handleExportToPdf(elementId!);
        }
      }}
      items={[
        {
          label: 'Export to excel',
          key: '0',
        },
        {
          label: 'Export to pdf',
          key: '1',
        },
      ]}
    />
  );

  return (
    <Dropdown.Button
      type='primary'
      overlay={menu}
      trigger={['click']}
      icon={<DownloadOutlined />}
    />
  );
};

export default DownloadButton;
