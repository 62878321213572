import * as _ from 'lodash';
import { USERS } from '../../../_mock/data';
import { FormDataUserTypes, AuthUserTypes } from '../@types';

const login = (formData: FormDataUserTypes) => {
  const { userName, isRemembered } = formData;

  const userData = _.find(USERS, (user) => user.name === userName);

  const payload: AuthUserTypes = {
    ...userData,
    isRemembered,
  };

  payload && localStorage.setItem('authUser', JSON.stringify(payload));

  return payload;
};

export const authService = {
  login,
};
