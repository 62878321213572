import { Provider } from 'react-redux';
import { store } from '../../../../redux/app/store';
import ProductModalForm from './ProductModalForm';

export const ProductModalContent = (isNew: boolean, isEdit: boolean) => {
  return (
    <Provider store={store}>
      <ProductModalForm isNew={isNew} isEdit={isEdit} />
    </Provider>
  );
};
