import { USERS, CUSTOMERS } from '../../_mock/data';
import { CustomerTypes, UserTypes } from '../../_mock/@types';
import type { DataNode } from 'antd/es/tree';

export const tableData: DataNode[] = USERS.filter(
  (user: UserTypes) => user.category === 'unitManager'
).map((unitManager: UserTypes) => ({
  title: unitManager.name,
  key: unitManager.id,
  children: USERS.filter(
    (children1: UserTypes) => children1.category === 'sectorManager'
  ).map((sectorManager: UserTypes) => ({
    title: sectorManager.name,
    key: sectorManager.id,
    children: USERS.filter(
      (children2: UserTypes) =>
        children2.category === 'officer' &&
        children2.managerId === sectorManager.id
    ).map((officer: UserTypes) => ({
      title: officer.name,
      key: officer.id,
      children: CUSTOMERS.filter(
        (customer: CustomerTypes) => customer.officerId === officer.id
      ).map((customer: CustomerTypes) => ({
        title: customer.name,
        key: `${officer.id}-${customer.id}`,
      })),
    })),
  })),
}));

export const tableDataWithoutCustomers: DataNode[] = USERS.filter(
  (user: UserTypes) => user.category === 'unitManager'
).map((unitManager: UserTypes) => ({
  title: unitManager.name,
  key: unitManager.id,
  children: USERS.filter(
    (children1: UserTypes) => children1.category === 'sectorManager'
  ).map((sectorManager: UserTypes) => ({
    title: sectorManager.name,
    key: sectorManager.id,
    children: USERS.filter(
      (children2: UserTypes) =>
        children2.category === 'officer' &&
        children2.managerId === sectorManager.id
    ).map((officer: UserTypes) => ({
      title: officer.name,
      key: officer.id,
    })),
  })),
}));
