import ReactDom from 'react-dom';
import {
  CustomerInfoTable,
  ProductAnalysisTables,
  ProfitabilityChart,
} from '../review-scenario-modal';

const ReviewScenarioPortal: React.FC = () => {
  return ReactDom.createPortal(
    <>
      <CustomerInfoTable isPortal={true} />
      <ProfitabilityChart isPortal={true} />
      <ProductAnalysisTables isPortal={true} />
    </>,
    document.getElementById('review-scenario-portal') as
      | Element
      | DocumentFragment
  );
};

export default ReviewScenarioPortal;
