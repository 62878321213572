import {
  CustomerTypes,
  ApprovalTypes,
  TransactionTypes,
  ScenariosTypes,
} from '../../../_mock/@types';

export const mergeById = (
  customers: CustomerTypes[],
  approvals: ApprovalTypes[],
  transactions: TransactionTypes[],
  scenarios: ScenariosTypes[]
) =>
  customers.map((customer) => ({
    ...customer,
    approvals: approvals.filter(
      (approval) => approval.customerId === customer.id && approval
    ),
    transactions: transactions.filter(
      (transaction) => transaction.customerName === customer.name
    ),
    scenarios: scenarios.filter(
      (scenario) => scenario.customerId === customer.id
    ),
    key: customer.id,
  }));
