import { TransactionTypes } from '../@types';

export const TRANSACTIONS: TransactionTypes[] = [
  {
    id: '1',
    date: Date.parse('01 Mar 2022'),
    paid: true,
    amount: 5436,
    type: 'repayment',
    customerId: '1',
    customerName: 'max',
  },
  {
    id: '2',
    date: Date.parse('05 Feb 2022'),
    paid: false,
    amount: 7866,
    type: 'installment',
    customerId: '1',
    customerName: 'max',
  },
  {
    id: '3',
    date: Date.parse('21 Mar 2022'),
    paid: true,
    amount: 9863,
    type: 'repayment',
    customerId: '2',
    customerName: 'josephine',
  },
  {
    id: '4',
    date: Date.parse('12 Apr 2022'),
    paid: false,
    amount: 1473,
    type: 'installment',
    customerId: '2',
    customerName: 'josephine',
  },
  {
    id: '5',
    date: Date.parse('01 Jul 2022'),
    paid: true,
    amount: 7853,
    type: 'repayment',
    customerId: '3',
    customerName: 'dereck',
  },
  {
    id: '6',
    date: Date.parse('26 Jan 2022'),
    paid: false,
    amount: 3689,
    type: 'installment',
    customerId: '3',
    customerName: 'dereck',
  },
  {
    id: '7',
    date: Date.parse('27 Feb 2022'),
    paid: true,
    amount: 3678,
    type: 'repayment',
    customerId: '4',
    customerName: 'devon',
  },
  {
    id: '8',
    date: Date.parse('25 May 2022'),
    paid: false,
    amount: 2976,
    type: 'installment',
    customerId: '4',
    customerName: 'devon',
  },
  {
    id: '9',
    date: Date.parse('11 Mar 2022'),
    paid: true,
    amount: 8357,
    type: 'repayment',
    customerId: '5',
    customerName: 'simon',
  },
  {
    id: '10',
    date: Date.parse('30 Jun 2022'),
    paid: false,
    amount: 5987,
    type: 'installment',
    customerId: '5',
    customerName: 'simon',
  },
  {
    id: '11',
    date: Date.parse('01 Apr 2022'),
    paid: true,
    amount: 9123,
    type: 'repayment',
    customerId: '6',
    customerName: 'dolly',
  },
  {
    id: '12',
    date: Date.parse('01 Jul 2022'),
    paid: false,
    amount: 6783,
    type: 'installment',
    customerId: '6',
    customerName: 'dolly',
  },
  {
    id: '13',
    date: Date.parse('22 May 2022'),
    paid: true,
    amount: 3489,
    type: 'repayment',
    customerId: '7',
    customerName: 'brad',
  },
  {
    id: '14',
    date: Date.parse('15 Apr 2022'),
    paid: false,
    amount: 5680,
    type: 'installment',
    customerId: '7',
    customerName: 'brad',
  },
  {
    id: '15',
    date: Date.parse('13 Jun 2022'),
    paid: true,
    amount: 8765,
    type: 'repayment',
    customerId: '8',
    customerName: 'keith',
  },
  {
    id: '16',
    date: Date.parse('26 Apr 2022'),
    paid: false,
    amount: 2134,
    type: 'installment',
    customerId: '8',
    customerName: 'keith',
  },
  {
    id: '17',
    date: Date.parse('18 Jul 2022'),
    paid: true,
    amount: 3678,
    type: 'repayment',
    customerId: '9',
    customerName: 'helen',
  },
  {
    id: '18',
    date: Date.parse('27 Jan 2022'),
    paid: false,
    amount: 5789,
    type: 'installment',
    customerId: '9',
    customerName: ' helen',
  },
  {
    id: '19',
    date: Date.parse('24 Mar 2022'),
    paid: true,
    amount: 7639,
    type: 'repayment',
    customerId: '10',
    customerName: 'anna',
  },
  {
    id: '20',
    date: Date.parse('13 Feb 2022'),
    paid: false,
    amount: 2978,
    type: 'installment',
    customerId: '10',
    customerName: 'anna',
  },
  {
    id: '21',
    date: Date.parse('29 Jul 2022'),
    paid: true,
    amount: 5436,
    type: 'repayment',
    customerId: '11',
    customerName: 'logan',
  },
  {
    id: '22',
    date: Date.parse('19 May 2022'),
    paid: false,
    amount: 8967,
    type: 'installment',
    customerId: '11',
    customerName: 'logan',
  },
  {
    id: '23',
    date: Date.parse('14 Apr 2022'),
    paid: true,
    amount: 7863,
    type: 'repayment',
    customerId: '12',
    customerName: 'anthony',
  },
  {
    id: '24',
    date: Date.parse('24 Jul 2022'),
    paid: false,
    amount: 2399,
    type: 'installment',
    customerId: '12',
    customerName: 'anthony',
  },
];
