import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import transactionService from './transactionService';
import { resetAllAction } from '../../../../redux/actions';
import { TransactionInitialStateTypes, DateTypes } from '../../@types';

const initialState: TransactionInitialStateTypes = {
  recentTransactions: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
};

export const selectTransactions = createAsyncThunk(
  'transactions/selectTransactions',
  (selectedDates: DateTypes) => {
    return transactionService.selectedTransactions(selectedDates);
  }
);

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(selectTransactions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(selectTransactions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.recentTransactions = action.payload;
      })
      .addCase(resetAllAction, () => initialState);
  },
});

export const { reset } = transactionSlice.actions;
export default transactionSlice.reducer;
