import { useAppSelector, useAppDispatch } from '../../../../redux/app/hooks';
import { selectApprovalStatus } from '../../redux/approvals/approvalSlice';
import {
  Descriptions,
  Typography,
  message,
  Card,
  Space,
  Input,
  Row,
  Col,
  Select,
  Button,
} from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { PRODUCT_OFFERS } from '../../../../_mock/data';
import { ProductOfferTypes } from '../../../../_mock/@types';

const { Title } = Typography;
const { Option } = Select;

const ApprovalModalInfo: React.FC = () => {
  const { selectedApprovalInfo } = useAppSelector((state) => state.approvals);
  const dispatch = useAppDispatch();

  const [product] = PRODUCT_OFFERS.filter(
    (product: ProductOfferTypes) =>
      product.name === selectedApprovalInfo?.productName
  );

  const handleClick = (id: string, status: 'approved' | 'rejected') => {
    const payload = {
      id,
      status,
    };

    dispatch(selectApprovalStatus(payload));

    if (status === 'approved') {
      message.success('Approved!');
    }

    if (status === 'rejected') {
      message.error('Rejected!');
    }
  };

  return (
    <>
      {selectedApprovalInfo && (
        <>
          <Title level={4}>Approval Info</Title>
          <Descriptions style={{ marginBottom: '4rem' }} bordered>
            <Descriptions.Item label='Requested By'>
              {selectedApprovalInfo!.userName}
            </Descriptions.Item>
            <Descriptions.Item label='Customer'>
              {selectedApprovalInfo!.customerName}
            </Descriptions.Item>
            <Descriptions.Item label='Product'>
              {selectedApprovalInfo!.productName}
            </Descriptions.Item>
            <Descriptions.Item label='Request Reason'>
              {selectedApprovalInfo!.requestReason}
            </Descriptions.Item>
            <Descriptions.Item label='Expiring Date' span={2}>
              {selectedApprovalInfo!.expirationDate.toString()}
            </Descriptions.Item>
          </Descriptions>

          {product && (
            <Card style={{ width: '100%', marginBottom: '1rem' }}>
              <Space
                direction='horizontal'
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Input.Group size='default'>
                  <Row gutter={8}>
                    <Col span={8}>
                      <Input disabled={true} value={product.name} />
                    </Col>
                    <Col span={8}>
                      <Input disabled={true} value={product.description} />
                    </Col>
                  </Row>
                  <Select
                    style={{
                      marginTop: '1rem',
                      marginBottom: '1rem',
                      width: '132px',
                    }}
                    defaultValue={product.type}
                    disabled={true}
                  >
                    <Option value={product.type}>{product.type}</Option>
                  </Select>

                  <Row style={{ marginBottom: '1rem' }} gutter={8}>
                    <Col span={8}>
                      <Input
                        disabled={true}
                        value={
                          product.serviceDiscountPercentage
                            ? product.serviceDiscountPercentage
                            : '-'
                        }
                      />
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={8}>
                      <Input
                        disabled={true}
                        value={
                          product.loanInterestRate
                            ? product.loanInterestRate
                            : '-'
                        }
                      />
                    </Col>
                    <Col span={8}>
                      <Input
                        disabled={true}
                        value={product.loanAmount ? product.loanAmount : '-'}
                      />
                    </Col>
                    <Col>
                      <Button
                        disabled={true}
                        type='text'
                        size='small'
                        icon={<MinusCircleOutlined />}
                      />
                    </Col>
                  </Row>
                </Input.Group>
              </Space>
            </Card>
          )}

          <Space
            direction='horizontal'
            style={{ display: 'flex', justifyContent: 'end' }}
          >
            <Button
              type='primary'
              style={{ backgroundColor: '#32CD32' }}
              onClick={() => handleClick(selectedApprovalInfo.id, 'approved')}
            >
              Approve
            </Button>
            <Button
              type='primary'
              style={{ backgroundColor: 'red' }}
              onClick={() => handleClick(selectedApprovalInfo.id, 'rejected')}
            >
              Reject
            </Button>
          </Space>
        </>
      )}
    </>
  );
};

export default ApprovalModalInfo;
