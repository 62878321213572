import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { Card } from 'antd';
import { data } from '../../data';

const ProfitabilityOwnedAmountChart = () => {
  return (
    <Card
      headStyle={{ backgroundColor: 'blue', color: 'white' }}
      title='Profitability / Owned Amount'
      bordered={false}
    >
      <LineChart
        width={400}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 10,
          left: -10,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='customerName' />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type='monotone'
          dataKey='totalProfitability'
          stroke='green'
          activeDot={{ r: 8 }}
        />
        <Line type='monotone' dataKey='amount' stroke='red' />
      </LineChart>
    </Card>
  );
};

export default ProfitabilityOwnedAmountChart;
