import { useAppSelector } from '../../../../redux/app/hooks';

import { Form, Input, Select } from 'antd';

type ProductModalFormPropTypes = {
  isNew: boolean;
  isEdit: boolean;
};

const ProductModalForm: React.FC<ProductModalFormPropTypes> = ({
  isEdit,
  isNew,
}) => {
  const { selectedNewProduct } = useAppSelector((state) => state.scenarios);

  const loanInterestRateNumber = selectedNewProduct?.loanInterestRate
    ? parseInt(selectedNewProduct.loanInterestRate.split('%')[0])
    : null;

  const transformedServiceDiscountPercentage = parseInt(
    selectedNewProduct.serviceDiscountPercentage.split('%')[0]
  );

  return (
    <Form labelCol={{ span: 9 }} wrapperCol={{ span: 14 }} layout='horizontal'>
      <Form.Item
        label='Name'
        name='name'
        rules={[{ required: true }]}
        initialValue={isNew ? null : selectedNewProduct.name}
      >
        <Input
          style={{ width: '80%' }}
          disabled={isNew ? false : true}
          // defaultValue={isNew ? null : selectedNewProduct.name}
        />
      </Form.Item>
      <Form.Item
        label='Type'
        name='type'
        initialValue={isNew ? null : selectedNewProduct.type}
      >
        <Select
          style={{ width: '80%' }}
          disabled={isNew ? false : true}
          // defaultValue={isNew ? null : selectedNewProduct.type}
        >
          <Select.Option value='loan'>Loan</Select.Option>
          <Select.Option value='service'>Service</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label='Service Discount Percentage'
        name='transformedServiceDiscountPercentage'
        initialValue={isNew ? null : transformedServiceDiscountPercentage}
      >
        <Input
          type='number'
          style={{ width: '50%' }}
          disabled={
            isNew || (isEdit && selectedNewProduct?.type === 'service')
              ? false
              : true
          }
          // defaultValue={isNew ? null : transformedServiceDiscountPercentage}
        />
      </Form.Item>
      <Form.Item
        label='Loan Amount'
        name='loanAmount'
        initialValue={isNew ? null : selectedNewProduct.loanAmount}
      >
        <Input
          type='number'
          style={{ width: '50%' }}
          disabled={
            isNew || (isEdit && selectedNewProduct?.type === 'loan')
              ? false
              : true
          }
          // defaultValue={isNew ? null : selectedNewProduct.loanAmount}
        />
      </Form.Item>
      <Form.Item
        label='Loan Interest Rate'
        name='loanInterestRate'
        initialValue={isNew ? null : selectedNewProduct.loanInterestRate}
      >
        <Input
          type='number'
          style={{ width: '50%' }}
          disabled={
            isNew || (isEdit && selectedNewProduct?.type === 'loan')
              ? false
              : true
          }
          // defaultValue={isNew ? null : selectedNewProduct.loanInterestRate}
        />
      </Form.Item>
      {loanInterestRateNumber! < 8 && loanInterestRateNumber !== null && (
        <Form.Item label='Exception Request Reason'>
          <Select style={{ width: '80%' }}>
            <Select.Option value='competition'>Competition</Select.Option>
            <Select.Option value='loyaltyProgramPromotion'>
              Loyalty Program Promotion
            </Select.Option>
            <Select.Option value='highProfitabilityException'>
              High Profitability Exception
            </Select.Option>
          </Select>
        </Form.Item>
      )}
    </Form>
  );
};

export default ProductModalForm;
