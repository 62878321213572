import * as _ from 'lodash';
import { TRANSACTIONS } from '../../../../_mock/data';
import { TransactionTypes } from '../../../../_mock/@types';
import { DateTypes } from '../../@types';

const selectedTransactions = (selectedDates: DateTypes) => {
  const { startDate, endDate } = selectedDates;

  const transactions: TransactionTypes[] = _.filter(
    TRANSACTIONS,
    (transaction) =>
      transaction.date >= startDate &&
      transaction.date <= endDate &&
      transaction.paid === true
  );

  return transactions;
};

const transactionService = {
  selectedTransactions,
};

export default transactionService;
