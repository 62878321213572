import { ExchangeTypes } from '../../@types';

const changeCurrency = (currency: string) => {
  let payload: ExchangeTypes | null = null;

  if (currency === 'EURO') {
    payload = {
      currency,
      exchangeRate: 1,
      moneySign: '€',
    };
  }
  if (currency === 'USD') {
    payload = {
      currency,
      exchangeRate: 1.1,
      moneySign: '$',
    };
  }

  return payload;
};

const changeCurrencyService = {
  changeCurrency,
};

export default changeCurrencyService;
