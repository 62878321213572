import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../redux/app/hooks';
import { selectScenario } from '../../redux/scenarios/scenarioSlice';
import { Space, Table, Tag, Button } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { EyeTwoTone, EditTwoTone } from '@ant-design/icons';
import { SCENARIOS, CUSTOMERS, USERS } from '../../../../_mock/data';
import { PATH_GENERAL } from '../../../../routes/paths';
import { mergeById } from '../../utils';
import { CustomerTypes, UserTypes } from '../../../../_mock/@types';
import { DataType } from '../../../dashboard/@types';
import { ScenariosTablePropTypes } from '../../@types';
import moment from 'moment';

const data = mergeById(SCENARIOS, CUSTOMERS);

const ScenariosTable: React.FC<ScenariosTablePropTypes> = ({
  handleScenariosTableData,
}) => {
  const [tableData, setTableData] = useState<any>(null);
  const { selectedUser } = useAppSelector((state) => state.hierarchyTree);
  const {
    exchange: { exchangeRate, moneySign },
  } = useAppSelector((state) => state.currency);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const finalData = data.map((data: any) => ({
    ...data,
    newProfitability: `${moneySign} ${Math.round(
      data.newProfitability * exchangeRate
    )}`,
    createdOn: moment(data.createdOn).format('DD/MM/YYYY'),
  }));

  useEffect(() => {
    if (tableData) {
      return handleScenariosTableData(tableData, 'scenarios-table');
    }
    handleScenariosTableData(finalData, 'scenarios-table');
    // eslint-disable-next-line
  }, [tableData, finalData]);

  useEffect(() => {
    //@ts-ignore
    if (selectedUser?.category) {
      const tableData = finalData.filter(
        (data: any) => data.officerId === selectedUser.id
      );
      setTableData(tableData);
    }
    // eslint-disable-next-line
  }, [selectedUser]);

  useEffect(() => {
    //@ts-ignore
    if (selectedUser?.officerId) {
      const tableData = finalData.filter(
        (data: any) => data[0].id === selectedUser.id
      );
      setTableData(tableData);
    }
    // eslint-disable-next-line
  }, [selectedUser]);

  useEffect(() => {
    if (!selectedUser) {
      setTableData(null);
    }
  }, [selectedUser]);

  const handleButtonClick = (scenario: any, isViewOnly: boolean) => {
    const payload = {
      ...scenario,
      isViewOnly,
    };

    dispatch(selectScenario(payload));

    const linkTo = PATH_GENERAL.selectedScenario(scenario.id);

    navigate(linkTo);
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: ['0', 'name'],
      key: 'name',
      filters: CUSTOMERS.map((customer: CustomerTypes) => ({
        text: customer.name,
        value: customer.name,
      })),
      onFilter: (value: any, record) => record[0].name.includes(value),
      sorter: (a, b) => a[0].name.length - b[0].name.length,
    },
    {
      title: 'Status',
      dataIndex: 'approvalStatus',
      key: 'status',
      render: (status: string) => {
        let color: string = 'blue';
        if (status === 'pending') {
          color = 'orange';
        }
        if (status === 'approved') {
          color = 'blue';
        }
        if (status === 'rejected') {
          color = 'error';
        }
        return (
          <Tag color={color} key={status}>
            {status}
          </Tag>
        );
      },
      filters: [
        {
          text: 'pending',
          value: 'pending',
        },
        {
          text: 'approved',
          value: 'approved',
        },
        {
          text: 'rejected',
          value: 'rejected',
        },
      ],
      onFilter: (value: any, record) => record.approvalStatus.includes(value),
    },
    {
      title: 'Profitability',
      dataIndex: 'newProfitability',
      key: 'newProfitability',
      sorter: {
        compare: (a, b) => {
          const A = parseInt(a.newProfitability.split(' ')[1]);
          const B = parseInt(b.newProfitability.split(' ')[1]);
          return A - B;
        },
      },
    },
    {
      title: 'Officer Name',
      dataIndex: ['createdBy', 'name'],
      key: 'officerName',
      filters: USERS.map((user: UserTypes) => ({
        text: user.name,
        value: user.name,
      })),
      onFilter: (value: any, record) => record.createdBy.name.includes(value),
      sorter: (a, b) => a.createdBy.name.length - b.createdBy.name.length,
    },
    {
      title: 'Created By',
      dataIndex: ['createdBy', 'name'],
      key: 'createdBy',
      filters: USERS.map((user: UserTypes) => ({
        text: user.name,
        value: user.name,
      })),
      onFilter: (value: any, record) => record.createdBy.name.includes(value),
      sorter: (a, b) => a.createdBy.name.length - b.createdBy.name.length,
    },
    {
      title: 'Created On',
      dataIndex: 'createdOn',
      key: 'createdOn',
      sorter: {
        compare: (a, b) => Date.parse(a.createdOn) - Date.parse(b.createdOn),
      },
    },
    {
      title: 'Actions',
      key: 'action',
      render: (_: any) => (
        <Space size='small'>
          <Button
            ghost={true}
            shape='circle'
            icon={<EyeTwoTone style={{ fontSize: '22px' }} />}
            onClick={() => handleButtonClick(_, true)}
          />
          <Button
            ghost={true}
            shape='circle'
            icon={
              <EditTwoTone
                style={{ fontSize: '22px' }}
                twoToneColor='#00A300'
              />
            }
            onClick={() => handleButtonClick(_, false)}
          />
        </Space>
      ),
    },
  ];

  const onChange: TableProps<DataType>['onChange'] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  return (
    <Table
      id='scenarios-table'
      dataSource={tableData ? tableData : finalData}
      columns={columns}
      onChange={() => onChange}
    />
  );
};

export default ScenariosTable;
