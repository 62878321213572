import { Col, Row } from 'antd';
import BankRevenueCard from './BankRevenueCard';
import TotalRepaymentCard from './TotalRepaymentCard';
import ClientProfitabilityCard from './ClientProfitabilityCard';

const CardList: React.FC = () => {
  return (
    <div style={{ marginTop: '2rem' }}>
      <Row>
        <Col span={8}>
          <BankRevenueCard />
        </Col>
        <Col span={8}>
          <TotalRepaymentCard />
        </Col>
        <Col span={8}>
          <ClientProfitabilityCard />
        </Col>
      </Row>
    </div>
  );
};

export default CardList;
