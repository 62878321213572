import { useState, useEffect } from 'react';
import { useAppSelector } from '../../../../redux/app/hooks';
import { Table, Modal, Space } from 'antd';
import { APPROVALS } from '../../../../_mock/data';
import type { ColumnsType } from 'antd/es/table';
import { ApprovalTypes } from '../../../../_mock/@types';
import { ApprovalModalPropTypes } from '../../@types';
import DownloadButton from '../../../../components/download-button';

const ApprovalsModal: React.FC<ApprovalModalPropTypes> = ({
  isVisible,
  handleCloseModal,
}) => {
  const [selectedApprovals, setSelectedApprovals] = useState<
    ApprovalTypes[] | undefined
  >(undefined);
  const { selectedScenario } = useAppSelector((state) => state.scenarios);

  useEffect(() => {
    if (selectedScenario) {
      const filteredApprovals = APPROVALS.filter(
        (approval) => approval.scenarioId === selectedScenario.id
      ).map((approval) => ({
        ...approval,
        key: approval.id,
      }));

      setSelectedApprovals(filteredApprovals);
    }
  }, [selectedScenario]);

  const columns: ColumnsType<any> = [
    {
      title: 'Requested By',
      dataIndex: 'userName',
      key: 'userName',
      filters: selectedApprovals?.map((approval) => ({
        text: approval.userName,
        value: approval.userName,
      })),
      onFilter: (value: any, record) => record.userName.includes(value),
      sorter: (a, b) => a.userName.length - b.userName.length,
    },
    {
      title: 'Customer',
      dataIndex: 'customerName',
      key: 'customerName',
      filters: selectedApprovals?.map((approval) => ({
        text: approval.customerName,
        value: approval.customerName,
      })),
      onFilter: (value: any, record) => record.customerName.includes(value),
      sorter: (a, b) => a.customerName.length - b.customerName.length,
    },
    {
      title: 'Product',
      dataIndex: 'productName',
      key: 'productName',
      filters: selectedApprovals?.map((approval) => ({
        text: approval.productName,
        value: approval.productName,
      })),
      onFilter: (value: any, record) => record.productName.includes(value),
      sorter: (a, b) => a.productName.length - b.productName.length,
    },
    {
      title: 'Request Reason',
      dataIndex: 'requestReason',
      key: 'requestReason',
      filters: selectedApprovals?.map((approval) => ({
        text: approval.requestReason,
        value: approval.requestReason,
      })),
      onFilter: (value: any, record) => record.requestReason.includes(value),
      sorter: (a, b) => a.requestReason.length - b.requestReason.length,
    },
    {
      title: 'Expiring Date',
      dataIndex: 'expirationDate',
      key: 'expirationDate',
      sorter: {
        compare: (a, b) =>
          Date.parse(a.expirationDate) - Date.parse(b.expirationDate),
      },
    },
  ];

  const handleOk = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    handleCloseModal();
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    handleCloseModal();
  };

  return (
    <Modal
      width={'60%'}
      title='Approvals'
      visible={isVisible}
      centered
      onOk={(e) => handleOk(e)}
      onCancel={(e) => handleCancel(e)}
      destroyOnClose
      maskClosable={true}
    >
      <Space
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '1rem',
        }}
      >
        <DownloadButton
          excelData={selectedApprovals}
          elementId={'approvals-modal-table'}
        />
      </Space>

      <Table
        id='approvals-modal-table'
        columns={columns}
        dataSource={selectedApprovals}
      />
    </Modal>
  );
};

export default ApprovalsModal;
