import { useEffect } from 'react';
import { useAppDispatch } from '../../../redux/app/hooks';
import { reset, setDates } from '../redux/date/dateSlice';

export const useSelectDates = (date: any) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (date) {
      const selectedDates = {
        startDate: Date.parse(date[0]._d),
        endDate: Date.parse(date[1]._d),
      };

      dispatch(setDates(selectedDates));
    }
    return () => {
      dispatch(reset());
    };
    // eslint-disable-next-line
  }, [date]);
};
