import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { resetAllAction } from '../../../../redux/actions';
import { ScenarioInitialStateTypes } from '../../@types';

const initialState: ScenarioInitialStateTypes = {
  selectedScenario: null,
  selectedNewProduct: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
};

export const selectScenario = createAsyncThunk(
  'scenarios/selectScenario',
  (scenarioData: any) => scenarioData
);
export const selectNewProduct = createAsyncThunk(
  'scenarios/selectNewProduct',
  (productData: any) => productData
);

export const scenarioSlice = createSlice({
  name: 'scenarios',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(selectScenario.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(selectScenario.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.selectedScenario = action.payload;
      })
      .addCase(selectNewProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(selectNewProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.selectedNewProduct = action.payload;
      })
      .addCase(resetAllAction, () => initialState);
  },
});

export const { reset } = scenarioSlice.actions;
export default scenarioSlice.reducer;
