import { createSlice } from '@reduxjs/toolkit';
import { resetAllAction } from '../../../../redux/actions';
import { ManagementInitialStateTypes } from '../../@types';

const initialState: ManagementInitialStateTypes = {
  selectedCustomer: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
};

export const managementSlice = createSlice({
  name: 'management',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
    selectCustomer: (state, action) => {
      state.isSuccess = true;
      state.selectedCustomer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllAction, () => initialState);
  },
});

export const { reset, selectCustomer } = managementSlice.actions;
export default managementSlice.reducer;
