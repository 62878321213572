import { ProductOfferTypes } from '../../../_mock/@types';

export const transformProducts = (
  selectedScenario: any,
  exchangeRate: number,
  moneySign: '€' | '$',
  isActualStatus: boolean
) => {
  const actualProducts: ProductOfferTypes[] = selectedScenario.products
    .filter((product: ProductOfferTypes) => product.isActual === isActualStatus)
    .map((product: ProductOfferTypes) => ({
      ...product,
      loanAmount: product.loanAmount
        ? `${moneySign} ${Math.round(product.loanAmount * exchangeRate)}`
        : null,
      loanInterestRate: product.loanInterestRate
        ? `${product.loanInterestRate * 10}%`
        : null,
      serviceDiscountPercentage: product.serviceDiscountPercentage
        ? `${product.serviceDiscountPercentage * 10}%`
        : null,
      key: product.id,
    }));

  return actualProducts;
};
