import { useState } from 'react';
import { Typography, Space } from 'antd';
import ScenariosTable from '../../components/scenarios-table';
import DownloadButton from '../../../../components/download-button';

const { Title } = Typography;

const ScenariosListPage: React.FC = () => {
  const [scenariosTableData, setScenariosTableData] = useState<any[] | null>(
    null
  );
  const [tableId, setTableId] = useState<string | null>(null);
  const handleScenariosTableData = (scenarios: any[], elementId: string) => {
    setScenariosTableData(scenarios);
    setTableId(elementId);
  };

  return (
    <div>
      <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title style={{ marginBottom: '1.5rem' }} level={2}>
          Scenarios List
        </Title>
        <Space>
          <DownloadButton excelData={scenariosTableData} elementId={tableId} />
        </Space>
      </Space>
      <ScenariosTable handleScenariosTableData={handleScenariosTableData} />
    </div>
  );
};

export default ScenariosListPage;
