import { useAppSelector } from '../../../../redux/app/hooks';
import { Card, Space, Input, Row, Col, Select, Button } from 'antd';
import {
  MinusCircleOutlined,
  ExclamationCircleTwoTone,
  CheckCircleTwoTone,
} from '@ant-design/icons';
import { ProductListItemPropTypes } from '../../@types';

const { Option } = Select;

const ProductListItem: React.FC<ProductListItemPropTypes> = ({
  isEdit,
  product,
}) => {
  const { authUser } = useAppSelector((state) => state.auth);

  return (
    <>
      {product && (
        <Card style={{ width: '100%', marginBottom: '1rem' }}>
          <Space
            direction='horizontal'
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Input.Group size='default'>
              <Row gutter={8}>
                <Col span={8}>
                  <Input
                    disabled={isEdit ? false : true}
                    value={product.name}
                  />
                </Col>
                <Col span={8}>
                  <Input
                    disabled={isEdit ? false : true}
                    value={product.description}
                  />
                </Col>
              </Row>
              <Select
                style={{
                  marginTop: '1rem',
                  marginBottom: '1rem',
                  width: '132px',
                }}
                defaultValue={product.type}
                disabled={isEdit ? false : true}
              >
                <Option value={product.type}>{product.type}</Option>
              </Select>

              <Row style={{ marginBottom: '1rem' }} gutter={8}>
                <Col span={8}>
                  <Input
                    disabled={isEdit && product.type !== 'loan' ? false : true}
                    value={product.serviceDiscountPercentage}
                  />
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={8}>
                  <Input
                    disabled={
                      isEdit && product.type !== 'service' ? false : true
                    }
                    value={product.loanInterestRate}
                  />
                </Col>
                <Col span={8}>
                  <Input
                    disabled={
                      isEdit && product.type !== 'service' ? false : true
                    }
                    value={product.loanAmount}
                  />
                </Col>
                <Col>
                  <Button
                    disabled={
                      isEdit && authUser?.category === 'unitManager'
                        ? false
                        : true
                    }
                    type='text'
                    size='small'
                    icon={<MinusCircleOutlined />}
                  />
                </Col>
              </Row>
            </Input.Group>
            <Space
              direction='horizontal'
              size='middle'
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              Status:
              <Space direction='vertical'>
                {product.approvalStatus === 'pending' ? (
                  <Row style={{ color: '#FFAC1C', marginTop: '8px' }}>
                    <ExclamationCircleTwoTone
                      style={{ fontSize: '1.5rem' }}
                      twoToneColor='#FFAC1C'
                    />
                    Pending Approval
                  </Row>
                ) : (
                  <Row style={{ color: '#32CD32', marginTop: '8px' }}>
                    <CheckCircleTwoTone
                      style={{ fontSize: '1.5rem' }}
                      twoToneColor='#32CD32'
                    />
                    Approved
                  </Row>
                )}
              </Space>
            </Space>
          </Space>
        </Card>
      )}
    </>
  );
};

export default ProductListItem;
