import { useAppSelector } from '../../../../redux/app/hooks';
import { Typography } from 'antd';
import AmountCards from '../../components/amount-cards';
import ActualProductsTable from '../../components/actual-products-table';
import NewProductsTable from '../../components/new-products-table';
import ReviewScenarioPortal from '../../components/review-scenario-portal';
import Spinner from '../../../../components/spinner';

const { Title } = Typography;

const ProductPage = () => {
  const { selectedScenario, isLoading } = useAppSelector(
    (state) => state.scenarios
  );

  if (isLoading)
    return (
      <p>
        <Spinner />
      </p>
    );

  return (
    <div style={{ width: '100%', margin: 'auto' }}>
      <Title style={{ marginBottom: '1.5rem' }} level={2}>
        Scenario #{selectedScenario.id} - {selectedScenario[0].name} -{' '}
        {selectedScenario.createdBy.name} - {selectedScenario.createdOn}
      </Title>

      <AmountCards />
      <ActualProductsTable />
      <NewProductsTable showActionButtons={true} />
      <ReviewScenarioPortal />
    </div>
  );
};

export default ProductPage;
