import { useAppSelector } from '../../../../redux/app/hooks';
import { Card, Table, Tag } from 'antd';
import { transformProducts } from '../../utils';
import { ProductOfferTypes } from '../../../../_mock/@types';
import type { ColumnsType } from 'antd/es/table';
import Spinner from '../../../../components/spinner';

const ActualProductsTable = () => {
  const { selectedScenario, isLoading } = useAppSelector(
    (state) => state.scenarios
  );
  const {
    exchange: { exchangeRate, moneySign },
  } = useAppSelector((state) => state.currency);

  if (isLoading) return <Spinner />;

  const actualProducts: ProductOfferTypes[] = transformProducts(
    selectedScenario,
    exchangeRate,
    moneySign,
    true
  );

  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      filters: actualProducts.map((product) => ({
        text: product.name,
        value: product.name,
      })),
      onFilter: (value: any, record) => record.name.includes(value),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (type: string) => {
        let color: string = 'success';
        if (type === 'loan') {
          color = 'orange';
        }
        return (
          <Tag color={color} key={type}>
            {type}
          </Tag>
        );
      },
      filters: [
        {
          text: 'loan',
          value: 'loan',
        },
        {
          text: 'service',
          value: 'service',
        },
      ],
      onFilter: (value: any, record) => record.type.includes(value),
    },
    {
      title: 'Service Discount Percentage',
      dataIndex: 'serviceDiscountPercentage',
      key: 'serviceDiscountPercentage',
      render: (value) => (value === null ? <>-</> : value),
    },
    {
      title: 'Loan Amount',
      dataIndex: 'loanAmount',
      key: 'loanAmount',
      sorter: {
        compare: (a, b) => {
          const A = parseInt(a.amount.split(' ')[1]);
          const B = parseInt(b.amount.split(' ')[1]);
          return A - B;
        },
      },
    },
    {
      title: 'Loan Interest Rate',
      dataIndex: 'loanInterestRate',
      key: 'loanInterestRate',
      sorter: {
        compare: (a, b) => {
          const A = parseInt(a.loanInterestRate.split('%')[0]);
          const B = parseInt(b.loanInterestRate.split('%')[0]);
          return A - B;
        },
      },
    },
  ];

  return (
    <Card
      headStyle={{
        backgroundColor: 'blue',
        color: 'white',
      }}
      title='Actual Products'
      bordered={false}
    >
      {actualProducts && (
        <Table columns={columns} dataSource={actualProducts} />
      )}
    </Card>
  );
};

export default ActualProductsTable;
