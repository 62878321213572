import { useAppSelector } from '../../../../redux/app/hooks';
import { Descriptions } from 'antd';
import { USERS } from '../../../../_mock/data';
import { transformCustomerInfoData } from '../../utils';
import { descriptionItemStyles, descriptionsDiv, descriptions } from './styles';
import { UserTypes } from '../../../../_mock/@types';
import { PortalPropTypes } from '../../@types';

const CustomerInfoTable: React.FC<PortalPropTypes> = ({ isPortal }) => {
  const {
    exchange: { exchangeRate, moneySign },
  } = useAppSelector((state) => state.currency);
  const { selectedScenario, isLoading } = useAppSelector(
    (state) => state.scenarios
  );

  if (isLoading) return <p>Loading ...</p>;

  const officerUsername = USERS.find(
    (user: UserTypes) => selectedScenario[0].officerId === user.id
  );

  const customerInfoData = transformCustomerInfoData(
    selectedScenario,
    officerUsername,
    moneySign,
    exchangeRate
  );

  const {
    amountDue,
    futureAmountDue,
    futureProfitability,
    name,
    officerName,
    productsOwned,
    profitability,
    transactionsCompleted,
    transactionsPending,
  } = customerInfoData;

  return (
    <div id='customer-info-div' style={isPortal ? descriptionsDiv : undefined}>
      <Descriptions bordered style={!isPortal ? descriptions : undefined}>
        <Descriptions.Item
          labelStyle={isPortal ? descriptionItemStyles : undefined}
          label='Name'
        >
          {name}
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={isPortal ? descriptionItemStyles : undefined}
          label='Profitability'
        >
          {profitability}
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={isPortal ? descriptionItemStyles : undefined}
          label='Products Owned'
        >
          {productsOwned}
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={isPortal ? descriptionItemStyles : undefined}
          label='Transactions Completed'
        >
          {transactionsCompleted}
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={isPortal ? descriptionItemStyles : undefined}
          label='Transactions Pending'
          span={2}
        >
          {transactionsPending}
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={isPortal ? descriptionItemStyles : undefined}
          label='Amount Due'
        >
          {amountDue}
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={isPortal ? descriptionItemStyles : undefined}
          label='Officer Name'
          span={2}
        >
          {officerName}
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={isPortal ? descriptionItemStyles : undefined}
          label='Future Profitability (1 year)'
        >
          {futureProfitability}
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={isPortal ? descriptionItemStyles : undefined}
          label='Future Amount Due (1year)'
          span={2}
        >
          {futureAmountDue}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default CustomerInfoTable;
