import { ProductTypes } from '../@types';

export const PRODUCTS: ProductTypes[] = [
  {
    id: '1',
    name: 'product 1',
    description: 'description of product 1',
    type: 'loan',
    serviceMaximumDiscountPercentage: null,
    loanMaximumAmount: 3567,
    loanMinimumInterestRate: 0.6,
  },
  {
    id: '2',
    name: 'product 2',
    description: 'description of product 2',
    type: 'service',
    serviceMaximumDiscountPercentage: 0.3,
    loanMaximumAmount: null,
    loanMinimumInterestRate: null,
  },
  {
    id: '3',
    name: 'product 3',
    description: 'description of product 3',
    type: 'loan',
    serviceMaximumDiscountPercentage: null,
    loanMaximumAmount: 8357,
    loanMinimumInterestRate: 0.4,
  },
  {
    id: '4',
    name: 'product 4',
    description: 'description of product 4',
    type: 'service',
    serviceMaximumDiscountPercentage: 0.5,
    loanMaximumAmount: null,
    loanMinimumInterestRate: null,
  },
  {
    id: '5',
    name: 'product 5',
    description: 'description of product 5',
    type: 'loan',
    serviceMaximumDiscountPercentage: null,
    loanMaximumAmount: 6789,
    loanMinimumInterestRate: 0.2,
  },
  {
    id: '6',
    name: 'product 6',
    description: 'description of product 6',
    type: 'service',
    serviceMaximumDiscountPercentage: 0.7,
    loanMaximumAmount: null,
    loanMinimumInterestRate: null,
  },
];
