import { USERS, CUSTOMERS } from '../../_mock/data';
import * as _ from 'lodash';
import { UserTypes, CustomerTypes } from '../../_mock/@types';

const selectUser = (userName: string) => {
  let payload = null;

  const [selectedUser]: UserTypes[] = _.filter(
    USERS,
    (user) => user.name === userName
  );

  const [selectedCustomer]: CustomerTypes[] = _.filter(
    CUSTOMERS,
    (customer) => customer.name === userName
  );

  if (selectedUser) {
    payload = selectedUser;
  }
  if (selectedCustomer) {
    payload = selectedCustomer;
  }

  return payload;
};

const findUserOrCustomer = (searchInput: string) => {
  let payload = undefined;

  const user = _.find(USERS, (user: UserTypes) => user.name === searchInput);

  const customer = _.find(
    CUSTOMERS,
    (customer: CustomerTypes) => customer.name === searchInput
  );

  if (user) {
    payload = user;
  }

  if (customer) {
    payload = {
      ...customer,
      id: `${customer?.officerId}-${customer?.id}`,
      customerId: customer?.id,
    };
  }

  if (!payload) {
    window.alert('Sorry, no user or customer found');
  }

  return payload;
};

const hierarchyTreeService = {
  selectUser,
  findUserOrCustomer,
};

export default hierarchyTreeService;
