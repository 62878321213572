import { Provider } from 'react-redux';
import { store } from '../../../../redux/app/store';
import ApprovalModalInfo from './ApprovalModalInfo';

export const ApprovalModalContent = () => {
  return (
    <Provider store={store}>
      <ApprovalModalInfo />
    </Provider>
  );
};
