import { Col, Row } from 'antd';
import ProfitabilityOwnedAmount from './ProfitabilityOwnedAmountChart';
import TransactionsChart from './TransactionsChart';

const CardCharts = () => {
  return (
    <Row style={{ marginBottom: '8rem' }} gutter={24}>
      <Col span={12}>
        <ProfitabilityOwnedAmount />
      </Col>
      <Col span={12}>
        <TransactionsChart />
      </Col>
    </Row>
  );
};

export default CardCharts;
