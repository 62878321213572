import { Space } from 'antd';
import ActualProductsTable from '../actual-products-table';
import NewProductsTable from '../new-products-table';
import {
  productAnalysisTableInPortal,
  productAnalysisTableInModal,
} from './styles';
import { PortalPropTypes } from '../../@types';

const ProductAnalysisTables: React.FC<PortalPropTypes> = ({ isPortal }) => {
  return (
    <Space
      id='product-analysis-table'
      direction='vertical'
      size='middle'
      style={
        isPortal ? productAnalysisTableInPortal : productAnalysisTableInModal
      }
    >
      <ActualProductsTable />
      <NewProductsTable showActionButtons={false} />
    </Space>
  );
};

export default ProductAnalysisTables;
