import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import {
  selectUser,
  findUserOrCustomer,
  clearSearch,
} from '../../redux/hierarchy-tree/hierarchyTreeSlice';
import { handleElementWidth } from '../../utils';
import { Space, Typography, Button, Input, Tree } from 'antd';
import {
  ExpandOutlined,
  ShrinkOutlined,
  DownOutlined,
} from '@ant-design/icons';
import { tableData, tableDataWithoutCustomers } from './hierarchy-tree-data';

const { Title } = Typography;
const { Search } = Input;

const Hierarchy: React.FC = () => {
  const [searchInput, setSearchInput] = useState<string>('');
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
  const { searchFilter } = useAppSelector((state) => state.hierarchyTree);
  const { authUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if (searchFilter) {
      setExpandedKeys([searchFilter.id]);
      setAutoExpandParent(true);
    }
  }, [searchFilter]);

  const handleUserSelect = (_: any, { node: { title: userName } }: any) => {
    dispatch(selectUser(userName));
  };

  const handleExpand = (newExpandedKeys: string[]) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  const handleClearSearch = () => {
    dispatch(clearSearch());
    setSearchInput('');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  return (
    <div
      id='hierarchy-div'
      style={{
        height: '100vh',
        width: '110%',
      }}
    >
      <Space
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
        direction='horizontal'
      >
        <Title level={3}>Hierarchy</Title>
        <Space>
          <Button
            shape='round'
            icon={<ShrinkOutlined />}
            onClick={() => handleElementWidth('hierarchy-div', '110%')}
          />
          <Button
            shape='round'
            icon={<ExpandOutlined />}
            onClick={() => handleElementWidth('hierarchy-div', '130%')}
          />
        </Space>
      </Space>
      <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {searchFilter && (
          <Button
            style={{ width: '4rem' }}
            shape='round'
            onClick={() => handleClearSearch()}
          >
            Clear
          </Button>
        )}
      </Space>

      <Search
        value={searchInput}
        placeholder='Search for users or customers'
        allowClear
        onChange={handleChange}
        onSearch={(value, event) => dispatch(findUserOrCustomer(searchInput))}
        style={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
      />
      <Tree
        //@ts-ignore
        onExpand={handleExpand}
        style={{ backgroundColor: '#f0f2f5', background: '#f0f2f5' }}
        switcherIcon={<DownOutlined />}
        defaultExpandedKeys={[authUser!.id!]}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        treeData={
          pathname === '/customer-management'
            ? tableDataWithoutCustomers
            : tableData
        }
        onSelect={handleUserSelect}
      />
    </div>
  );
};

export default Hierarchy;
