import { Typography } from 'antd';
import CardCharts from '../../components/card-charts';
import CustomersTable from '../../components/customers-table';

const { Title } = Typography;

const CustomerManagementPage: React.FC = () => (
  <div style={{ width: '120vh', left: 20 }}>
    <Title style={{ marginBottom: '1.5rem' }} level={2}>
      Customer Management
      <hr />
    </Title>

    <CardCharts />
    <CustomersTable />
  </div>
);

export default CustomerManagementPage;
