import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../redux/app/hooks';
import { loginUser, reset } from '../../redux/authSlice';
import { Button, Checkbox, Form, Input } from 'antd';
import { PATH_GENERAL } from '../../../../routes/paths';
import { FormTypes } from '../../@types';

const formDataInitialState: FormTypes = {
  userName: '',
  password: '',
};

const LoginForm: React.FC = () => {
  const [formData, setFormData] = useState<FormTypes>(formDataInitialState);
  const [isRemembered, setIsRemembered] = useState<boolean>(false);
  const { authUser, isSuccess } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (authUser && isSuccess) {
      navigate(PATH_GENERAL.root);
      dispatch(reset());
    }
    // eslint-disable-next-line
  }, [authUser]);

  const { userName, password } = formData;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCheckboxChange = (): void => {
    setIsRemembered((prevState) => !prevState);
  };

  const handleFormSubmit = (): void => {
    const payload = {
      userName,
      isRemembered,
    };

    if (!userName || !password) {
      return alert('Please Add All Fields!');
    }

    dispatch(loginUser(payload));
  };

  return (
    <Form
      style={{ width: '70%' }}
      name='basic'
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      autoComplete='off'
    >
      <Form.Item
        label='Username'
        name='userName'
        rules={[{ required: true, message: 'Please add your username!' }]}
      >
        <Input onChange={handleInputChange} name='userName' value={userName} />
      </Form.Item>

      <Form.Item
        label='Password'
        name='password'
        rules={[{ required: true, message: 'Please add your password!' }]}
      >
        <Input.Password
          onChange={handleInputChange}
          name='password'
          value={password}
        />
      </Form.Item>

      <Form.Item
        name='isRemember'
        valuePropName='checked'
        wrapperCol={{ offset: 8, span: 16 }}
      >
        <Checkbox name='isRemembered' onChange={handleCheckboxChange}>
          Remember me
        </Checkbox>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type='primary' onClick={handleFormSubmit}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
